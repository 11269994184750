import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/joy";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export type CardItemProps = {
  url: string;
  image: string;
  available: boolean;
  itemName: string;
  children?: React.ReactNode | React.ReactNode[];
  width?: number;
  height?: number;
};

export const CardItem = (props: CardItemProps) => {
  return (
    <Grid key={`card-grid-${props.itemName}`} xs={12} md={4}>
      <Card
        variant={"outlined"}
        color="neutral"
        size="md"
      >
        <Box>
          <LazyLoadImage
            alt={props.itemName}
            style={{
              maxWidth: "200px",
              width: "auto",
              maxHeight: "200px",
            }}
            src={props.image}
            width={`200`}
            height={`200`}
          />
        </Box>
        <Box>
          {!props.available && (
            <LazyLoadImage
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
              src={
                "https://centralarkansasinflatables.com/cdn-cgi/imagedelivery/6QiASA1pHsoYecw9egSmhw/3f5e0da9-d4dd-410c-b4c6-6ce815765300/512"
              }
              width={"200"}
              height={"200"}
              alt={"Sold Out"}
            />
          )}
        </Box>

        <Link
          href={props.url}
          overlay={true}
          underline="none"
          rel={'canonical'}
          aria-label={`${props.itemName} from Central Arkansas Inflatable`}
        >
          <CardContent
            sx={{
              textAlign: "left",
            }}
            orientation="vertical"
          >
            <div>
              <Typography level="title-md">{props.itemName}</Typography>
            </div>
            {props.children && props.children}
          </CardContent>
        </Link>
      </Card>
    </Grid>
  );
};

export default CardItem;
