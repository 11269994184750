import type { State, DateRange } from "./types";
import { SET_START_DATE, SET_END_DATE } from "./types";
import { combineReducers } from "redux";

const initialState: DateRange = {
  startInstant: null,
  endInstant: null,
};

const startInstant = (state = initialState.startInstant, action: any) => {
  switch (action.type) {
    case SET_START_DATE:
      return action.startInstant;
    default:
      return state;
  }
};

const endInstant = (state = initialState.endInstant, action: any) => {
  switch (action.type) {
    case SET_END_DATE:
      return action.endInstant;
    default:
      return state;
  }
};
export default combineReducers({
  startInstant,
  endInstant,
});

export const getStartDate = (state: State) => state.date.startInstant;
export const getEndDate = (state: State) => state.date.endInstant;

export const getDateRange = (state: State): DateRange => {
  return state.date;
};
