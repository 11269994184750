import { Box, Button, Option, Select, Textarea, Typography } from "@mui/joy";

import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  getDamageWaiver,
  getSetupSurface,
  getShoppingId,
} from "../../reducers/customer";
import { AddressElement, Elements } from "@stripe/react-stripe-js";
import type {
  Stripe,
  StripeAddressElementChangeEvent,
} from "@stripe/stripe-js";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { BASE_URL } from "../../index";
import type { Address, CartFormData } from "../CartPage";
import { getCartItems } from "../../reducers/cart";
import {captureException, captureMessage} from "@sentry/react";

type EventInfoPageProps = {
  stripe: Promise<Stripe | null>;
  submit: (
    lineItems: FormattedPaymentInfoLineItem[],
    clientSecret: string,
    formData: CartFormData,
    address: Address,
    damageWaiver: boolean,
    totals: { totalAmount: number; depositAmount: number },
  ) => void;
  updateDamageWaiver: (damageWaiver: boolean) => void;
  formData: CartFormData | undefined;
  address: Address | undefined;
};

export type FormattedPaymentInfoLineItem = {
  name: string;
  itemAmount: number | undefined;
};

export const EventInfoPage = (props: EventInfoPageProps) => {
  const shoppingId = useSelector(getShoppingId);
  const ref = useRef<HTMLFormElement | null>(null);
  const damageWaiver = useSelector(getDamageWaiver);
  const damageWaiverEnabled =
    damageWaiver.damageWaiver && damageWaiver.damageWaiverPercentage > 0;
  const cartCount = useSelector(getCartItems).length;
  const setupSurface = useSelector(getSetupSurface);
  const [formData, setFormData] = React.useState<CartFormData>(
    props.formData || {
      email: "",
      damageWaiver: true,
      setupSurface: "",
      customerNotes: "",
      emailOffers: false,
      firstName: "",
      lastName: "",
      phone: "",
    },
  );
  const [address, setAddress] = React.useState<Address>(
    props.address || {
      line1: "",
      line2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "US",
    },
  );
  const [status, setStatusState] = useState("idle");
  const [error, setError] = useState<Record<string, string> | null>({});

  const setStatus = (status: string, timeout?: number) => {
    setStatusState(status);
    setTimeout(() => {
      setStatusState("idle");
    }, timeout || 5000);
  };

  async function handleClick() {
    const errors: Record<string, string> = {};

    if (status === "no-items") {
      errors.status = "Please add some items to your cart";
    }

    if (formData.email === "") {
      errors.email = "Please enter your email address";
    }

    if (formData.firstName === "") {
      errors.firstName = "Please enter your first name";
    }

    if (formData.lastName === "") {
      errors.lastName = "Please enter your last name";
    }

    if (formData.setupSurface === "") {
      errors.setupSurface = "Please enter your setup surface";
    }

    if (!cartCount || cartCount === 0) {
      errors.status = "Please add some items to your cart";
    }

    const incompleteAddress = Object.entries(address)
      .filter((entry) => {
        return entry[0] !== "line2";
      })
      .some((value) => {
        return value[1] === null || value[1] === "";
      });

    if (incompleteAddress) {
      errors.address = "Please enter your address";
    }

    if (errors && Object.keys(errors).length > 0) {
      setError(errors);
      return;
    }

    if (!shoppingId) {
      captureMessage("No Shopping Id");
      setError({ status: "Unable to find cart, try again." });
      console.error("No shopping ID");
      return;
    }

    setStatus("loading", 60_000);
    setError(null);

    try {
      await fetch(`${BASE_URL}/cart/link`, {
        method: "POST",
        body: JSON.stringify({
          shoppingCartId: shoppingId,
          email: formData.email,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      captureException(error);
      setStatus("idle");
      console.error(error);
      setError({ status: "Unable to link cart, try again." });
      return;
    }

    try {
      const finalize = await fetch(`${BASE_URL}/cart/finalize`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          shoppingId: shoppingId,
          damageWaiverApplied: damageWaiverEnabled && formData.damageWaiver,
          setupSurface: formData.setupSurface,
          customerData: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            phoneNumber: formData.phone,
            customerNotes: formData.customerNotes,
          },
          location: {
            line1: address.line1,
            line2: address.line2,
            postalCode: address.postal_code,
            city: address.city,
            state: address.state,
            country: address.country,
          },
        }),
      });
      const finalizeData = (await finalize.json()) as {
        status: boolean;
        paymentIntent: string;
        totalAmount: number;
        depositAmount: number;
        lineItems: FormattedPaymentInfoLineItem[];
      };
      if (!finalizeData.status) {
        captureMessage("Unable to finalize cart", {
          level: "error",
          extra: {
            data: finalizeData
          }
        })
        setStatus("idle");
        setError({ status: "Unable to finalize cart, try again." });
        console.error("Unable to finalize cart");
        return;
      }
      setStatus("idle");
      props.submit(
        finalizeData.lineItems,
        finalizeData.paymentIntent,
        formData,
        address,
        damageWaiverEnabled && formData.damageWaiver,
        {
          totalAmount: finalizeData.totalAmount,
          depositAmount: finalizeData.depositAmount,
        },
      );
    } catch (error) {
      captureException(error);
      console.error(error);
      setError({ status: "Unable to finalize cart, try again." });
    }
  }

  const handleChange = (event: any) => {
    const { name, value, type, checked } = event.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
    if (name === "damageWaiver") {
      props.updateDamageWaiver(checked);
    }
  };

  if (cartCount === 0) {
    return (
      <Box
        sx={{
          textAlign: "center",
          border: "1px solid #cccccc",
          borderRadius: "5px",
          marginTop: "10px",
        }}
      >
        <Typography>
          <p>Your cart is empty</p>
        </Typography>
      </Box>
    );
  }

  return (
    <Elements stripe={props.stripe}>
      <Box
        component="form"
        ref={(item: HTMLFormElement) => {
          ref.current = item;
        }}
        onSubmit={(event) => {
          event.preventDefault();
        }}
        sx={{ mt: 1 }}
      >
        <Typography component="h5" mb={1}>
          Contact
        </Typography>
        <TextField
          error={error?.email !== undefined}
          margin="normal"
          fullWidth
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        {error?.email !== undefined && (
          <Typography textColor={"red"}>{error.email}</Typography>
        )}
        {/*<FormControlLabel*/}
        {/*  control={*/}
        {/*    <Checkbox*/}
        {/*      checked={formData.emailOffers}*/}
        {/*      onChange={handleChange}*/}
        {/*      name="emailOffers"*/}
        {/*    />*/}
        {/*  }*/}
        {/*  label="Email me with special offers and promotions"*/}
        {/*/>*/}
        <Typography component="h5" mb={1} mt={2}>
          Event Information
        </Typography>
        <AddressElement
          onChange={(e: StripeAddressElementChangeEvent) => {
            setFormData({
              ...formData,
              ...e.value,
            });
            if (e.value.address) {
              setAddress(e.value.address);
            }
          }}
          options={{
            mode: "shipping",
            allowedCountries: ["US"],
            blockPoBox: true,
            defaultValues: {
              firstName: formData.firstName,
              lastName: formData.lastName,
              phone: formData.phone,
              address: {
                line1: address.line1,
                line2: address.line2 || "",
                city: address.city,
                state: address.state,
                postal_code: address.postal_code,
                country: address.country,
              },
            },
            display: {
              name: "split",
            },
            fields: {
              phone: "always",
            },
            validation: {
              phone: {
                required: "never",
              },
            },
          }}
        />
        {address?.city !== "" && (
          <>
            <Box mt={2}>
              <Typography>Setup Surface</Typography>
              <Select
                name={"setupSurface"}
                value={formData.setupSurface}
                onChange={(event, item) => {
                  setFormData({
                    ...formData,
                    setupSurface: item ?? "",
                  });
                }}
                sx={{
                  borderColor: "grey.400",
                  borderRadius: "5px",
                  backgroundColor: "white",
                  padding: ".75rem",
                  width: "100%",
                  "&:focus": {
                    borderColor: "grey.400",
                  },
                }}
              >
                {setupSurface.map((surface) => {
                  return <Option value={surface}>{surface}</Option>;
                })}
              </Select>
              {error?.setupSurface !== undefined && (
                <Typography textColor={"red"}>{error.setupSurface}</Typography>
              )}
            </Box>
            <Box mt={2}>
              <Typography>Additional Notes</Typography>
              <Textarea
                id={"customerNotes"}
                name={"customerNotes"}
                value={formData.customerNotes}
                onChange={handleChange}
                sx={{
                  borderColor: "grey.400",
                  borderRadius: "5px",
                  backgroundColor: "white",
                  padding: ".75rem",
                  width: "100%",
                  height: "100px",
                  resize: "none",
                  "&:focus": {
                    borderColor: "grey.400",
                  },
                }}
              />
            </Box>
            {damageWaiverEnabled && (
              <FormControlLabel
                sx={{
                  marginTop: 2,
                }}
                control={
                  <Checkbox
                    checked={formData.damageWaiver}
                    onChange={handleChange}
                    name="damageWaiver"
                  />
                }
                label={`Include Damage Waiver (${damageWaiver.damageWaiverPercentage}% of total)`}
              />
            )}
          </>
        )}

        {error !== undefined && (
          <Typography textColor={"red"}>
            {Object.keys(error || {}).map((entry) => {
              if (!error || entry === "email") {
                return null;
              }
              const value = error[entry];

              return <p key={entry}>{value}</p>;
            })}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          mt={2}
        >
          <Button
            aria-label={"Checkout"}
            type="submit"
            onClick={handleClick}
            disabled={status === "loading"}
            sx={{
              backgroundColor: "#7fce2b",
              "&:hover": {
                backgroundColor: "#3fa624",
              },
              py: 2,
              px: 3,
              borderRadius: 2,
              "&:disabled": {
                bgcolor: "grey.300",
                color: "common.white",
              },
            }}
          >
            {status !== "loading" ? "Checkout" : <CircularProgress size={24} />}
          </Button>
        </Box>
      </Box>
    </Elements>
  );
};

export default EventInfoPage;
