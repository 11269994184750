import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import reducers from "./reducers";
import {thunk} from "redux-thunk";
import ReactGA from 'react-ga4'

const localStorageMiddleware = ({ getState }: any) => {
  return (next: any) => (action: any) => {
    const result = next(action);
    sessionStorage.setItem("applicationState", JSON.stringify(getState()));
    return result;
  };
};

const reHydrateStore = () => {
  if (sessionStorage.getItem("applicationState") !== null) {
    const parsedResponse = JSON.parse(
      sessionStorage.getItem("applicationState") || "{}",
    );
    if (parsedResponse.date.startInstant !== null) {
      parsedResponse.date.startInstant = new Date(
        parsedResponse.date.startInstant,
      );
    }
    if (parsedResponse.date.endInstant !== null) {
      parsedResponse.date.endInstant = new Date(parsedResponse.date.endInstant);
    }
    return parsedResponse;
  }
};

const middleware = [localStorageMiddleware, thunk];

const store = createStore(
  reducers,
  reHydrateStore(),
  applyMiddleware(...middleware),
);

export const BASE_BACKEND_URL =
    process.env.BACKEND_URL || "https://dash.partyrentalplatform.com";

export const SITE_URL =
    process.env.SITE_URL || "https://centralarkansasinflatables.com";
export const BASE_URL = `${BASE_BACKEND_URL}/api/public/CentralArkansasInflatables`;

ReactGA.initialize('G-0WYEK0JR96')
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://5954f1792e5908c18f1ecb5d73625037@o4506622727421952.ingest.sentry.io/4506708420263936",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [`${BASE_BACKEND_URL}/`],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  tracePropagationTargets: ["localhost", `${BASE_BACKEND_URL}/`],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
