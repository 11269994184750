import { Button, CircularProgress, Typography } from "@mui/material";
import React, {useMemo, useState} from "react";
import { AddShoppingCart } from "@mui/icons-material";
import type { CartItem as CartEntry } from "../types";
import {
  Box,
  DialogContent,
  DialogTitle,
  Link,
  List,
  ListDivider,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  styled,
} from "@mui/joy";
import CartItem from "../components/CartItem";
import { useDispatch, useSelector } from "react-redux";
import { getDateRange } from "../reducers/date";
import { BASE_URL } from "../index";
import DateSelector from "../components/DateSelector";
import {
  getProductAvailability,
  getProductByNameOrId,
} from "../reducers/products";
import { getCartItems } from "../reducers/cart";
import { updateCartItem } from "../reducers/actions";
import { captureException } from "@sentry/react";
import SEO from "../components/SEO";

const Logo = styled("img")({
  height: "82px",
});

const AddToCart = ({ productId }: { productId: number }) => {
  const dispatch = useDispatch<any>();
  const cartItems = useSelector(getCartItems);
  const [cartContainerOpen, setCartContainerOpen] = useState(false);
  const { startInstant, endInstant } = useSelector(getDateRange);
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [loadingDateSelector, setLoadingDateSelector] = useState(false);
  const [editingDates, setEditingDates] = useState(false);
  const product = useSelector(getProductByNameOrId(productId.toString()));
  const availability = useSelector(getProductAvailability(productId));

  if (!product) {
    return null;
  }

  const dates = useMemo(() => {
    const currentBlocked = availableDates;
    currentBlocked.push(new Date());
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    currentBlocked.push(tomorrow);
    currentBlocked.push(new Date(2024, 4, 19))
    return currentBlocked;
  }, [availableDates])

  const available = product?.new === true ? false : availability ?? product?.quantity;
  const inCart =
    cartItems !== undefined &&
    cartItems.find((item) => item.id === productId) !== undefined;
  let buttonName = "Reserve";


  if (available <= 0) {
    buttonName = "Out of Stock";
  } else if (inCart) {
    buttonName = "View In Cart";
  }

  if (product?.new === true) {
    buttonName = "Coming Soon Call for Scheduling"
  }

  const fetchAvailableDates = async (startTime?: Date) => {
    try {
      const startQuery = startTime
        ? `month=${startTime.getUTCMonth()}&year=${startTime.getUTCFullYear()}`
        : "";
      const response = await fetch(
        `${BASE_URL}/products/${productId}/availability?${startQuery}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const data = (await response.json()) as any;
      setLoadingDateSelector(false);
      return data.availability;
    } catch (e) {
      captureException(e);

      console.log(e);
      return false;
    }
  };

  const onMonthChange = async (month: number, year: number) => {
    fetchAvailableDates(new Date(year, month, 1)).then((availability) => {
      setAvailableDates(
        availability.map((item: any) => {
          const date = new Date(item.end);
          date.setUTCSeconds(0);
          date.setUTCMilliseconds(0);
          return date;
        }),
      );
    });
  };

  const dateSelector = () => {
    if (loadingDateSelector) {
      return <CircularProgress />;
    }
    return (
      <div>
        <SEO title={'Central Arkansas Inflatables Cancellation Policy'} description={'Bounce House weather and cancellation policy. Proudly serving Little Rock, Benton, Bryant, Sherwood, Maumelle, and more!'} name={'CentralArkansasInflatables'} type={''} />
        <Box
          sx={{
            display: { xs: "none", lg: "block" },
          }}
        >
          <DateSelector
            mobile={false}
            blockedDays={dates}
            onChangeMonth={async (month, year) => {
              await onMonthChange(month, year);
            }}
            onSubmit={() => {
              setShowDateSelector(false);
              if (!editingDates) {
                forceAddToCart();
              }
              setEditingDates(false);
            }}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "block", lg: "none" },
          }}
        >
          <DateSelector
            mobile={true}
            blockedDays={dates}
            onChangeMonth={async (month, year) => {
              await onMonthChange(month, year);
            }}
            onSubmit={() => {
              setShowDateSelector(false);
              if (!editingDates) {
                forceAddToCart();
              }
              setEditingDates(false);
            }}
          />
        </Box>
      </div>
    );
  };

  const forceAddToCart = () => {
    setCartContainerOpen(true);
    if (inCart) {
      return;
    }
    const cartItem: CartEntry = {
      id: productId,
      quantity: 1,
      name: product.name,
      price: product.price,
      image: product.heroImage,
    };
    dispatch(updateCartItem(cartItem));
  };

  const addDateSelector = async (editing?: boolean) => {
    setEditingDates(editing ?? false);
    setShowDateSelector(true);
    setLoadingDateSelector(true);
    const availability = await fetchAvailableDates();
    setAvailableDates(
      availability.map((item: any) => {
        const date = new Date(item.end);
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(0);
        return date;
      }),
    );
  };

  const addToCart = async () => {
    if (!startInstant || !endInstant) {
      await addDateSelector();
      return;
    }
    forceAddToCart();
  };

  return (
    <Box
      sx={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Modal
        open={cartContainerOpen}
        onClose={() => setCartContainerOpen(false)}
      >
        <ModalDialog
          size={"lg"}
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
            },
          })}
        >
          <ModalClose />
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Logo
              src="https://centralarkansasinflatables.com/cdn-cgi/imagedelivery/6QiASA1pHsoYecw9egSmhw/381ff54a-dcbe-4fea-c5c4-f6210e66fd00/512"
              alt="Central Arkansas Inflatables"
            />
          </DialogTitle>
          <DialogContent>
            {cartItems.length === 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "24px",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" color="text.primary">
                  Your cart is empty
                </Typography>
                <Link
                  underline={"none"}
                  href={"/rentals"}
                  aria-label={"Shop All"}
                >
                  <Button
                    aria-label={"Shop All"}
                    variant="outlined"
                    color={"primary"}
                    onClick={() => {
                      setCartContainerOpen(false);
                    }}
                  >
                    Shop All
                  </Button>
                </Link>
                <Link
                  underline={"none"}
                  href={"/category"}
                  aria-label={"Shop By Category"}
                >
                  <Button
                    aria-label={"Shop By Category"}
                    variant="outlined"
                    color={"secondary"}
                    onClick={() => {
                      setCartContainerOpen(false);
                    }}
                  >
                    Shop By Category
                  </Button>
                </Link>
              </Box>
            )}
            <List>
              {cartItems.map((entry: CartEntry) => (
                <>
                  <CartItem key={entry.id} item={entry} editable={true} />
                  <ListDivider key={`${entry.id}-divider`} inset={"gutter"} />
                </>
              ))}
            </List>
          </DialogContent>
          {/* Footer to go to checkout page*/}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              href={"/cart"}
              underline={"none"}
              aria-label={"Proceed to Checkout"}
            >
              <Button
                aria-label={"Proceed to Checkout"}
                onClick={() => {
                  setCartContainerOpen(false);
                }}
                startIcon={<AddShoppingCart />}
                variant="contained"
                color="success"
                sx={{
                  mt: 2,
                  px: 3,
                  py: 1,
                  borderRadius: 1,
                  fontSize: "14px",
                  textTransform: "uppercase",
                }}
              >
                Proceed to Checkout
              </Button>
            </Link>
          </Box>
        </ModalDialog>
      </Modal>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{
          mt: 2,
          px: 3,
          py: 1,
        }}
      >
        <Button
          aria-label={"Add to Cart"}
          onClick={() => {
            addToCart();
          }}
          variant="contained"
          sx={{
            width: "100%",
            backgroundColor: "#ee3625",
            borderRadius: 3,
            fontFamily: "Helvetica",
            fontSize: "16px",
            fontWeight: "400",
          }}
          disabled={showDateSelector || available <= 0}
        >
          {buttonName}
        </Button>

        {!product?.new && available <= 0 && !showDateSelector && (
          <Button
            aria-label={"Edit Dates"}
            onClick={() => {
              addDateSelector(true);
            }}
            variant="contained"
            sx={{
              backgroundColor: "#25a1ee",
              borderRadius: 3,
              fontFamily: "Helvetica",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            Edit Dates
          </Button>
        )}
        {product.new && (
          <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
                textAlign: "center",
                }}
              >
                (501) 779-8433
          </Typography>
            )}
      </Stack>
      {showDateSelector && dateSelector()}
    </Box>
  );
};

export default AddToCart;
