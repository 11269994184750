import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getAllProductAvailability,
  getCategories,
  getProductInCategory,
  isProductLoading,
} from "../reducers/products";
import NotFoundPage from "./NotFoundPage";
import { Container, Grid, Typography } from "@mui/joy";
import React, { useCallback } from "react";
import CartBanner from "../components/CartBanner";
import CardItem from "../components/CardItem";
import BackButton from "../components/BackButton";
import type { ProductFromBackend } from "../types";
import SEO from "../components/SEO";

const freeCities = [
    "benton",
    "bryant",
    "alexander",
    "little-rock",
    "north-little-rock",
    "haskell",
    "shannon-hills",
    "bauxite",
];

const paidCities = [
    "maumelle",
    "conway",
    "mayflower",
    "cabot",
    "jacksonville",
    "sherwood",
    "wrightsville",
    "college-station",
];

const CategoryShopping = () => {
  const { categoryId, location } = useParams();
  const loading = useSelector(isProductLoading);
  const allCategories = useSelector(getCategories);
  const category = allCategories.find(
    (category) =>
      category.id.toString() === categoryId ||
      category.name.toLowerCase() === categoryId?.toLowerCase(),
  );
  const products = useSelector(getProductInCategory(category?.id || -1));
  const allAvailableProducts = useSelector(getAllProductAvailability);

  const getAvailability = useCallback(
    (productId: number) => {
      return (
        allAvailableProducts[productId] ??
        products.find((product) => product.id === productId)?.quantity ??
        1
      );
    },
    [allAvailableProducts],
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!products) {
    return <NotFoundPage />;
  }

  let locationPretty = location?.replace(/-/g, " ");
  locationPretty = locationPretty?.replace(/\b\w/g, (l) => l.toUpperCase());
  locationPretty = locationPretty?.replace("Ar", "Arkansas");
  const freeContains = locationPretty && freeCities.some(item => locationPretty?.toLowerCase().split(" ").includes(item));
  const locationString = location && (freeContains || paidCities.some(item => locationPretty?.toLowerCase().split(" ").includes(item))) ? `We proudly deliver to ${locationPretty}.${!freeContains ? " Travel fees may apply at checkout." : ""}` : "";

  // TODO make the top box equally sized, fix the font, and make it just look better
  // we'll prolly make it an image or something
  return (
    <Container
      sx={{
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
      <SEO
          title="Central Arkansas Inflatables - Categories"
          description="View all Bounce House, Water Slide, and Inflatable Rentals from Central Arkansas Inflatables. Proudly serving Little Rock, Benton, Bryant, Sherwood, Maumelle, and more!"
          name="CentralArkansasInflatables"
          type=""
      />
      <CartBanner />
      <Grid
        container
        alignItems="center"
        textAlign={"center"}
        spacing={2}
        sx={{
          border: "1px solid #cccccc",
          borderRadius: "5px",
          paddingBottom: "10px",
        }}
      >
        <BackButton />
        <Grid xs={12} md={12}>
          <Typography level={"h1"} textAlign={"center"} textColor={"gray"}>
            {category?.name}
          </Typography>
          {location && (
            <Typography level={"h3"} textAlign={"center"}>
              {locationString}
            </Typography>
            )}
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        textAlign={"center"}
        spacing={2}
        paddingTop={3}
      >
        {products.map((product: ProductFromBackend, index: number) => {
          return (
            <CardItem
              url={`/product/${product.id}/${product.slug}`}
              key={`product-category-${index}`}
              image={
                product.heroImage ||
                "https://via.placeholder.com/300x300?text=No+Image+Available"
              }
              itemName={product.name}
              available={getAvailability(product.id) > 0}
            >
              <div>
                <Typography fontSize="lg" fontWeight="lg">
                  {`$${product.price}`}
                </Typography>
              </div>
            </CardItem>
          );
        })}
      </Grid>
    </Container>
  );
};

export default CategoryShopping;
