import React from "react";
import { Box, Link, Typography } from "@mui/joy";
import { useSelector } from "react-redux";
import { getDateRange } from "../reducers/date";
import EditIcon from "@mui/icons-material/Edit";

const CartBanner = () => {
  const { startInstant, endInstant } = useSelector(getDateRange);

  if (!startInstant || !endInstant) {
    return null;
  }

  const formattingOptions: any = {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "numeric",
  };

  if (startInstant.getDay() !== endInstant.getDay()) {
    formattingOptions.month = "numeric";
  }
  const dateTimeFormat = new Intl.DateTimeFormat("en", formattingOptions);

  return (
    <Box
      my={"2rem"}
      sx={{
        textAlign: "center",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Typography fontSize={"24px"} fontWeight={"600"} lineHeight={"20px"}>
        Date Selected
      </Typography>
      <Typography
        id={"edit-date"}
        component={"p"}
        endDecorator={
          <Link
            variant="outlined"
            aria-labelledby={"edit-date"}
            href={"/order-by-date"}
            fontSize="md"
            borderRadius="sm"
          >
            <EditIcon aria-label={'Edit Date'} fontSize={"medium"} />
          </Link>
        }
      >
        {dateTimeFormat.formatRange(startInstant, endInstant)}
      </Typography>
    </Box>
  );
};

export default CartBanner;
