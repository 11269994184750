import { Box } from "@mui/joy";
import { ChevronLeft } from "@mui/icons-material";
import React from "react";

const SimplePrevArrow = (props: any) => {
  return (
    <Box
      onClick={props.onClick}
      sx={{
        position: "absolute",
        zIndex: 1,
        top: "50%",
        left: "-50px",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          border: "1px solid #4D5559",
          width: "36px",
          height: "36px",
          borderRadius: "50%",
          textColor: "#4D5559",
        }}
      >
        <ChevronLeft
          fontSize={"medium"}
          style={{
            color: "#4D5559",
          }}
          sx={{ color: "#4D5559" }}
        />
      </Box>
    </Box>
  );
};

export default SimplePrevArrow;
