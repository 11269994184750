import { useParams } from "react-router-dom";
import NotFoundPage from "./NotFoundPage";
import React, { useEffect, useMemo, useState } from "react";
import {
  AspectRatio,
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Skeleton,
  Typography,
} from "@mui/joy";
import AddToCart from "./AddToCart";
import { useSelector } from "react-redux";
import {
  getCategory,
  getProductByNameOrId,
  isProductLoading,
} from "../reducers/products";
import CartBanner from "../components/CartBanner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SEO from "../components/SEO";
import { viewItem } from "../lib/events";
import Slider from "react-slick";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",

        zIndex: 1,
        right: "16px",
        bottom: "20px",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          border: "1px solid #4D5559",
          width: "36px",
          height: "36px",
          borderRadius: "50%",
          textColor: "#4D5559",
        }}
      >
        <ChevronRight
          fontSize={"medium"}
          style={{
            color: "#4D5559",
          }}
          sx={{ color: "#4D5559" }}
        />
      </Box>
    </Box>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",

        zIndex: 1,
        right: "60px",
        bottom: "20px",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          border: "1px solid #4D5559",
          width: "36px",
          height: "36px",
          borderRadius: "50%",
          textColor: "#4D5559",
        }}
      >
        <ChevronLeft
          fontSize={"medium"}
          style={{
            color: "#4D5559",
          }}
          sx={{ color: "#4D5559" }}
        />
      </Box>
    </Box>
  );
}
const ProductPage = () => {
  const ref = React.useRef<Slider | null>(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const { productId } = useParams();
  const product = useSelector(getProductByNameOrId(productId || ""));
  const category = useSelector(
    getCategory(product?.categoryId?.toString() || ""),
  );
  const loading = useSelector(isProductLoading);

  useEffect(() => {
    if (product) {
      viewItem(product.name, product.price, category?.name);
    }
  }, [product]);

  const settings = {
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
        },
      },
    ],
    beforeChange: (previousSlide: number, nextSlide: number) => {
      setCarouselIndex(nextSlide);
    },
  };

  const slides = useMemo(() => {
    if (!product) {
      return [];
    }
    return product.altImages
      ? [product.heroImage, ...product.altImages]
      : [product.heroImage];
  }, [product]);

  if (!product && !loading) {
    return <NotFoundPage />;
  }

  return (
    <Container
      sx={{
        paddingTop: 2,
      }}
    >
      <SEO
        title={`Central Arkansas Inflatables - ${product?.name}`}
        description="Rent a Bounce House, Water Slide, Bounce Combo, or Obstacle Course from Central Arkansas Inflatables. Proudly serving Little Rock, Benton, Bryant, Sherwood, Maumelle, and more!"
        name="CentralArkansasInflatables"
        type="product"
      />
      <CartBanner />
      <Grid
        container
        spacing={{
          xs: 1,
          md: 16,
        }}
      >
        <Grid xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Breadcrumbs aria-label="breadcrumbs">
            <Link
              key={"home"}
              color="neutral"
              href="/"
              aria-label={"Go to Central Arkansas Inflatables Home"}
            >
              Home
            </Link>
            <Link
              key={"category"}
              color="neutral"
              href="/"
              aria-label={`Shop for all items in the ${category?.name} category`}
            >
              {!loading && category?.name}
            </Link>
            <Box />
          </Breadcrumbs>
          <Typography
            component={"h1"}
            sx={{
              fontSize: 40,
              fontWeight: 700,
            }}
          >
            {product?.name ?? <Skeleton variant={"text"} />}
          </Typography>
          <Typography
            textColor={"#212a2f"}
            sx={{
              fontSize: 16,
              fontWeight: 400,
              lineHeight: 1.5,
            }}
          >
            {`$${product?.price}`}
          </Typography>
          <Box
            sx={{
              display: { md: "flex", xs: "none" },
              flexDirection: "column",
            }}
          >
            {product && <AddToCart productId={product.id} />}

            <Typography component={"p"}>
              <div
                dangerouslySetInnerHTML={{ __html: product?.description || "" }}
              />
              {!product?.description && <Skeleton variant={"text"} />}
            </Typography>
          </Box>
        </Grid>

        <Grid xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <Box width={"100%"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column-reverse", md: "row" },
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "column" },
                  gap: "1.1%",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              >
                {slides.map((item, index) => {
                  return (
                    <Box
                      onClick={() => {
                        if (ref.current) {
                          ref.current?.slickGoTo(index);
                        }
                      }}
                      key={`${index}-preview-product-box`}
                      border={
                        carouselIndex === index
                          ? "1px solid #4D5559"
                          : "2px solid transparent"
                      }
                      borderRadius={"5px"}
                      overflow={"hidden"}
                      padding={"0px"}
                      width={"70px"}
                      height={"70px"}
                      mb={"16px"}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <AspectRatio
                        key={`${index}-preview-product-ratio`}
                        ratio={"1/1"}
                        sx={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <LazyLoadImage
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                          height={"250"}
                          width={"250"}
                          key={`${index}-preview-product-img`}
                          src={item}
                          alt={product?.name}
                        />
                      </AspectRatio>
                    </Box>
                  );
                })}
              </Box>
              <Box maxWidth={"100%"} width={"100%"}>
                {loading ? (
                  <Skeleton variant={"rectangular"} width={"100%"} />
                ) : (
                  <Slider
                    ref={(item) => {
                      ref.current = item;
                    }}
                    {...settings}
                  >
                    {slides.map((item, index) => {
                      return (
                        <AspectRatio
                          key={`${index}-aspect`}
                          ratio={"1/1"}
                          sx={{
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <LazyLoadImage
                            style={{
                              height: "auto",
                              width: "100%",
                            }}
                            width={"100%"}
                            key={`${index}-product`}
                            src={item}
                            alt={product?.name}
                          />
                        </AspectRatio>
                      );
                    })}
                  </Slider>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: { md: "none", xs: "flex" },
          flexDirection: "column",
        }}
      >
        {product && <AddToCart productId={product.id} />}

        <Typography component={"p"}>
          <div
            dangerouslySetInnerHTML={{ __html: product?.description || "" }}
          />
          {!product?.description && <Skeleton variant={"text"} />}
        </Typography>
      </Box>
    </Container>
  );
};

export default ProductPage;
