import React from "react";
import experimentalStyled from "@mui/styled-engine";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import { Box } from "@mui/joy";
import Navbar from "../components/Navbar";
import { HelmetProvider } from "react-helmet-async";

const MainLayoutRoot = experimentalStyled("div")(() => ({
  top: 0,
  left: 0,
  width: "100%",
  zIndex: -10,
  position: "absolute",
}));

function MainLayout() {
  return (
    <MainLayoutRoot>
      <HelmetProvider>
        <Navbar />
        <Box
          sx={{
            minHeight: "100vh",
          }}
        >
          <Outlet />
        </Box>

        <Box
          sx={{
            mt: "15px",
          }}
        >
          <Footer />
        </Box>
      </HelmetProvider>
    </MainLayoutRoot>
  );
}

export default MainLayout;
