import React, {useCallback} from "react";
import RentalCard from "../components/RentalCard";
import { useSelector } from "react-redux";
import {
  getAllProductAvailability,
  getProducts,
  isProductLoading,
} from "../reducers/products";
import type { ProductFromBackend } from "../types";
import CartBanner from "../components/CartBanner";
import { Container, Grid, Typography } from "@mui/joy";
import SEO from "../components/SEO";

const Rentals = () => {
  const products = useSelector(getProducts);
  const loading = useSelector(isProductLoading);
  const allProductAvailability = useSelector(getAllProductAvailability);

  if (!loading && !products) {
    return <Typography component="h6">Error: no products</Typography>;
  }

    const getAvailability = useCallback(
        (productId: number) => {
            return (
                allProductAvailability[productId] ??
                products.find((product) => product.id === productId)?.quantity ??
                1
            );
        },
        [allProductAvailability],
    );

  return (
    <Container>
      <SEO
        title="Central Arkansas Inflatables - Rentals"
        description="View all Bounce House, Water Slide, and Inflatable Rentals from Central Arkansas Inflatables. Proudly serving Little Rock, Benton, Bryant, Sherwood, Maumelle, and more!"
        name="CentralArkansasInflatables"
        type=""
      />
      <CartBanner />
      <Typography level={"h2"} textAlign={"center"}>
        Shop All
      </Typography>
      <Grid container spacing={2} alignItems="center" textAlign={"center"}>
        {products.map((product: ProductFromBackend) => (
          <RentalCard
            key={`${product.id}-item`}
            product={product}
            available={loading ? true : getAvailability(product.id) > 0}
          />
        ))}
      </Grid>
    </Container>
  );
};

export default Rentals;
