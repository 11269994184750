import { Container, Typography } from "@mui/joy";
import React from "react";
import SEO from "../../components/SEO";

const PrivacyPage = () => {
  return (
    <Container>
      <SEO title={'Central Arkansas Inflatables - Privacy Policy'} description={'See how we protect your data at Central Arkansas Inflatables!'} name={'CentralArkansasInflatables'} type={''} />

      <Typography level={"h1"} textColor={"orange"}>
        Privacy Policy
      </Typography>
      <Typography component={"p"}>Privacy Policy</Typography>
      <Typography component={"p"}>Last Updated: 02/14/2024</Typography>
      <Typography component={"p"}>
        <br />
        Central Arkansas Inflatables respects the privacy of our users. This
        Privacy Policy explains how we collect, use, disclose, and safeguard
        your information when you visit our website. Please read this privacy
        policy carefully.
      </Typography>
      <Typography component={"p"}>
        <br />
        1. Information We Collect
        <br />
        Personal Data: We may collect personal information such as your name,
        email address, and telephone number.
        <br />
        Derivative Data: Information our servers automatically collect when you
        access the site, such as your IP address, browser type, operating
        system, and access times.
        <br />
        Financial Data: Credit card numbers or other payment-related
        information.
      </Typography>
      <Typography component={"p"}>
        <br />
        2. Use of Information
        <ul>
          <li>To create and manage your account.</li>
          <li>To email you regarding your account or order.</li>
          <li>
            To fulfill and manage purchases, orders, payments, and other
            transactions.
          </li>
        </ul>
      </Typography>
      <Typography component={"p"}>
        3. Disclosure of Information
        <br />
        We may share information with vendors, consultants, and other service
        providers. Such as: Facebook, Google, and other advertising platforms.
        We also may share information with our business partners for payment processing or debugging.
        <br />
        We may share information if we are legally required to do so.
        <br />
        <br />
        4. Security of Your Information
        <br />
        We use administrative, technical, and physical security measures to
        protect your personal information.
        <br />
        <br />
        6. Policy for Children
        <br />
        We do not knowingly solicit information from or market to children under
        the age of 13.
        <br />
        <br />
        8. Contact Us
        <br />
        If you have questions or comments about this Privacy Policy, please
        contact us at:
        <br />
        <br />
        Phone: (501) 779-8433
        <br />
        Email: tre@centralarkansasinflatables.com
        <br />
        Address: 3003 Sue St Benton AR 72015
        <br />
        <br />
        In the event that you suffer a loss due to unauthorized use of the
        credit card, according to law, you must notify your credit card company
        immediately based on the reporting rules and procedures which they have
        provided you.
      </Typography>
    </Container>
  );
};

export default PrivacyPage;
