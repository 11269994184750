import React, { useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

interface AnchorProps {
  id: string;
}

export default function Anchor(props: AnchorProps) {
  const { id } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  const handleHashChange = useCallback(() => {
    if (location.hash.substring(1) === id && ref.current) {
      // Smoothly scroll to the div
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [id, location, ref]);

  useEffect(() => {
    // Initial check
    handleHashChange();
    // Listen for hash changes
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      // Cleanup
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [handleHashChange]);

  return (
    <div
      id={id}
      ref={(item) => {
        ref.current = item;
      }}
    />
  );
}
