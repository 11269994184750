import { combineReducers } from "redux";
import type {
  ProductsAction,
  ProductsState,
  State,
  UpdateProductAvailability,
} from "./types";
import { RECEIVE_PRODUCTS, UPDATE_PRODUCT_AVAILABILITY } from "./types";
import type { Category, ProductFromBackend } from "../types";

const initialState: ProductsState = {
  allProducts: {},
  displayedProducts: [],
  displayedCategories: [],
  availability: {},
  allCategories: {},
  loading: true,
};

const allProducts = (
  state = initialState.allProducts,
  action: ProductsAction,
) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        ...action?.products?.products.reduce((obj: any, product: any) => {
          obj[product.id] = product;
          return obj;
        }, {}),
      };
    default:
      return state;
  }
};

const displayedProducts = (
  state = initialState.displayedProducts,
  action: ProductsAction,
): any => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return action?.products?.products.filter(
        (product: any) => product.display,
      );
    default:
      return state;
  }
};

const availability = (
  state = initialState.availability,
  action: ProductsAction,
) => {
  switch (action.type) {
    case UPDATE_PRODUCT_AVAILABILITY:
      return {
        ...state,
        ...action?.availability?.reduce(
          (obj: Record<number, number>, product: UpdateProductAvailability) => {
            obj[product.id] = product.quantity;
            return obj;
          },
          {},
        ),
      };
    default:
      return state;
  }
};

const allCategories = (
  state = initialState.allCategories,
  action: ProductsAction,
) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return {
        ...state,
        ...action.products.categories.reduce((obj: any, category: any) => {
          obj[category.id] = category;
          return obj;
        }, {}),
      };
    default:
      return state;
  }
};

const displayedCategories = (
  state = initialState.displayedCategories,
  action: ProductsAction,
) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return action.products.categories.filter(
        (category: any) => category.display,
      );
    default:
      return state;
  }
};

const loading = (state = initialState, action: ProductsAction) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  displayedProducts,
  displayedCategories,
  allProducts,
  availability,
  allCategories,
  loading,
});

export const getProduct =
  (id: number | string) =>
  (state: State): ProductFromBackend => {
    if (typeof id === "string") {
      id = parseInt(id);
    }
    return state.products.allProducts[id];
  };

export const getProductByNameOrId =
  (nameOrId: string) =>
  (state: State): ProductFromBackend | undefined => {
    const product = getProduct(nameOrId)(state);
    if (product) {
      if (!product.display) {
        return undefined;
      }
      return product;
    }
    return getProducts(state).find(
      (product: ProductFromBackend) =>
        product.name === nameOrId || product.id.toString() === nameOrId,
    );
  };

export const getProductAvailability =
  (id: number) =>
  (state: State): number =>
    state.products.availability[id];

export const getAllProductAvailability = (
  state: State,
): Record<number, number> => {
  return state.products.availability;
};

export const getProductInCategory = (category: number) => (state: State) => {
  return getProducts(state).filter(
    (product: ProductFromBackend) => product.categoryId === category || product.subCategoryId === category,
  );
};

export const isProductLoading = (state: State): boolean =>
  state.products.loading;

export const getCategories = (state: State): Category[] => {
  return state?.products?.displayedCategories || [];
};

export const getCategory =
  (id: string | number) =>
  (state: State): Category => {
    if (typeof id === "string") {
      id = parseInt(id);
    }
    return state.products.allCategories[id];
  };

export const getProducts = (state: State): ProductFromBackend[] => {
  return state?.products?.displayedProducts || [];
};
