import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Box } from "@mui/joy";

const Badge = styled(Box)({
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#10B981", // emerald-500 color
  color: "white",
  fontSize: "0.75rem", // equivalent to text-xs
  position: "absolute",
  width: "1.5rem", // equivalent to w-6
  height: "1.25rem", // equivalent to h-5
  bottom: "1.5rem", // equivalent to bottom-6
  right: "-0.25rem", // equivalent to -right-1
});

const CartBadge = ({ count }: { count: number | undefined }) => {
  return count && count > 0 ? (
    <div>
      {count > 0 && (
        <Badge>
          <Typography>{count}</Typography>
        </Badge>
      )}
    </div>
  ) : (
    <></>
  );
};

export default CartBadge;
