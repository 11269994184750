import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/joy";
import type { Address, CartFormData } from "../pages/CartPage";
import type { StripeAddressElementChangeEvent } from "@stripe/stripe-js";
import { BASE_URL, SITE_URL } from "../index";
import { getShoppingId } from "../reducers/customer";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import {captureException, captureMessage} from "@sentry/react";

type PaymentInfoProps = {
  formData: CartFormData;
  address: Address;
  startingTotals: {
    totalAmount: number;
    depositAmount: number;
  };
};

const PaymentInfo = ({
  formData,
  address,
  startingTotals,
}: PaymentInfoProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const shoppingId = useSelector(getShoppingId);
  const [tip, setTip] = useState<number | null>(0);
  const [paymentAmount, setPaymentAmount] = useState<
    "payFullAmount" | "payDeposit" | "customAmount"
  >("payFullAmount");
  const [paymentAmountValue, setPaymentAmountValue] = useState<number | null>(
    0,
  );
  const [billingAddress, setBillingAddress] = useState<Address>(address);
  const [complete, setComplete] = useState<boolean>(false);

  const [billingFormData, setBillingFormData] =
    useState<CartFormData>(formData);
  const [billingAddressSame, setBillingAddressSame] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Record<string, string>>({});

  const [amountCharged, setAmountCharged] = useState<number | undefined>(
    startingTotals.totalAmount,
  );

  const handleUpdateCheckout = async (
    loading: boolean,
    addressToUse: Address,
    tipAmount: number | null,
    paymentAmount: string,
    paymentAmountValue: number | null,
  ) => {
    if (!stripe || !elements) {
      return false;
    }
    if (loading) {
      setLoading(true);
    }
    const errors: Record<string, string> = {};

    if (
      paymentAmount === "customAmount" &&
      (paymentAmountValue === null ||
        paymentAmountValue <= startingTotals.depositAmount ||
        paymentAmountValue > startingTotals.totalAmount)
    ) {
      errors.paymentAmountValue =
        "Invalid custom payment amount, it must be between the deposit amount and the total amount.";
    }

    if (tipAmount === null || tipAmount < 0 || tipAmount > 100) {
      errors.tipAmount = "Invalid tip amount, it must be between $0 and $100";
    }

    if (Object.values(errors).length > 0) {
      setError(errors);
      if (loading) {
        setLoading(false);
      }
      return false;
    }

    const request = await fetch(`${BASE_URL}/cart/checkout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shoppingId: shoppingId,
        paymentAmount:
          paymentAmount === "customAmount"
            ? paymentAmountValue
            : paymentAmount === "payFullAmount"
              ? startingTotals.totalAmount
              : startingTotals.depositAmount,
        tipAmount: tipAmount || 0,
        billingAddress: {
          line1: addressToUse.line1,
          line2: addressToUse.line2 ?? undefined,
          postalCode: addressToUse.postal_code,
          city: addressToUse.city,
          state: addressToUse.state,
          country: addressToUse.country,
        },
      }),
    });
    if (loading) {
      setLoading(false);
    }
    if (!request.ok) {
      captureMessage("Payment Info Error", {
        level: "error",
        extra: {
          statusCode: request.status,
        }
      });
      setError({ status: "Error in checkout", ...error });
      return false;
    }
    const json: Record<string, any> = await request.json();
    if (!json || json?.error) {
      captureMessage("Payment Info Error", {
        level: "error",
        extra: {
          json,
        }
      });
      setError(json?.error || "Unknown error");
      return false;
    }
    setAmountCharged(json.amount);
    setError({});
    return true;
  };

  const handleSubmit = async (event: any) => {
    setLoading(true);
    setError({});
    event.preventDefault();
    if (!stripe || !elements) {
      captureMessage("Stripe Loading Error", {
        level: "error"
      });
      setError({ status: "Error in checkout, try refreshing." });
      setLoading(false);
      return;
    }
    const addressToUse = billingAddressSame ? address : billingAddress;
    const formDataToUse = billingAddressSame ? formData : billingFormData;

    try {
      const success = await handleUpdateCheckout(
        false,
        addressToUse,
        tip,
        paymentAmount,
        paymentAmountValue,
      );
      if (!success) {
        setLoading(false);
        return;
      }
    } catch (e) {
      captureException(e);
      setLoading(false);
      console.log(e);
      setError({ status: "Error in checkout" });
      return;
    }

    if (amountCharged === undefined || amountCharged < 1 || amountCharged > 2000) {
      captureMessage("Amount Charged Error", {
        level: "error",
        extra: {
          amountCharged,
        }
      });
      setError({ status: "Sorry for the innocence, but for an order this size please call the office to confirm!"})
      setLoading(false);
      return;
    }

    const confirmPayment = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            address: {
              city: addressToUse.city,
              country: addressToUse.country,
              line1: addressToUse.line1,
              line2: addressToUse.line2 || "",
              postal_code: addressToUse.postal_code,
              state: addressToUse.state,
            },
            email: formDataToUse.email,
            name: `${formDataToUse.firstName} ${formDataToUse.lastName}`,
            phone: formDataToUse.phone,
          },
        },
        return_url: `${SITE_URL}/finalized`,
      },
    });

    if (confirmPayment.error) {
      captureMessage("Stripe Error", {
        level: "error",
        extra: {
          error: confirmPayment.error,
        }
      });
      if (confirmPayment.error.message) {
        setError({ status: confirmPayment.error.message });
      }
    }

    setLoading(false);
  };

  return (
    <Box>
      <Typography component="h5" mb={1} mt={2}>
        Payment
      </Typography>
      <PaymentElement
        options={{
          layout: "accordion",
          fields: {
            billingDetails: {
              name: "auto",
              email: "never",
              phone: "never",
              address: "never",
            },
          },
        }}
      />

      <Box mt={2}>
        <Typography component="h5">Billing address</Typography>
        <Typography component="p">
          Select the address that matches your payment method
        </Typography>
        <RadioGroup
          defaultValue={"sameAddress"}
          name={"billingAddressRadio"}
          value={billingAddressSame ? "sameAddress" : "differentAddress"}
          onChange={() => {
            const newValue = !billingAddressSame;
            setBillingAddressSame(newValue);
            const addressToUse = newValue ? address : billingAddress;
            handleUpdateCheckout(
              true,
              addressToUse,
              tip,
              paymentAmount,
              paymentAmountValue,
            );
          }}
          sx={{
            my: 1,
            border: "1px solid",
            borderColor: "divider",
            "& > span": {
              mt: 0,
              py: 1,
              px: 2,
              "&:not(:last-child)": {
                borderBottom: "1px solid",
                borderColor: "divider",
              },
            },
          }}
        >
          <Radio value={"sameAddress"} label={"Same as event address"} />
          <Radio
            value={"differentAddress"}
            label={"Use a different billing address"}
          />
          {!billingAddressSame && (
            <Box
              sx={{
                py: 1,
                px: 2,
                backgroundColor: "background.level1",
              }}
            >
              <AddressElement
                onChange={(e: StripeAddressElementChangeEvent) => {
                  setBillingFormData({
                    ...formData,
                    ...e.value,
                  });
                  if (e.value.address) {
                    setBillingAddress(e.value.address);
                  }
                  setComplete(e.complete);
                }}
                onBlur={() => {
                  if (complete) {
                    handleUpdateCheckout(
                      true,
                      billingAddress,
                      tip,
                      paymentAmount,
                      paymentAmountValue,
                    );
                  }
                }}
                options={{
                  mode: "billing",
                  allowedCountries: ["US"],
                  blockPoBox: true,
                  display: {
                    name: "split",
                  },
                  fields: {
                    phone: "never",
                  },
                }}
              />
            </Box>
          )}
        </RadioGroup>
      </Box>
      <Box mt={2}>
        <Typography component="h5">Payment Amount</Typography>
        <Typography component="p">
          All bookings require some payment up front. You can choose to pay the
          deposit only, or the full amount.
        </Typography>
        <RadioGroup
          defaultValue={"payFullAmount"}
          name={"amountRadio"}
          value={paymentAmount}
          onChange={(value) => {
            setPaymentAmount(value.target.value as any);
            let paymentAmt = paymentAmountValue;
            if (value.target.value === "customAmount") {
              setPaymentAmountValue(startingTotals.totalAmount);
              paymentAmt = startingTotals.totalAmount;
            }
            const addressToUse = billingAddressSame ? address : billingAddress;
            handleUpdateCheckout(
              true,
              addressToUse,
              tip,
              value.target.value,
              paymentAmt,
            );
          }}
          sx={{
            my: 1,
            border: "1px solid",
            borderColor: "divider",
            "& > span": {
              mt: 0,
              py: 1,
              px: 2,
              "&:not(:last-child)": {
                borderBottom: "1px solid",
                borderColor: "divider",
              },
            },
          }}
        >
          <Radio
            disabled={loading}
            value={"payFullAmount"}
            label={`Full Total $${startingTotals.totalAmount}`}
          />
          <Radio
            disabled={loading}
            value={"payDeposit"}
            label={`Deposit Only $${startingTotals.depositAmount}`}
          />
          <Radio
            disabled={loading}
            value={"customAmount"}
            label={`Custom Payment Amount`}
          />

          {paymentAmount === "customAmount" && (
            <Box
              sx={{
                py: 1,
                px: 2,
                backgroundColor: "background.level1",
              }}
            >
              <FormControl error={error.paymentAmountValue !== undefined}>
                <FormLabel>Payment Amount</FormLabel>
                <Input
                  placeholder="Payment Amount"
                  startDecorator={
                    <Typography
                      sx={{
                        mr: 1,
                      }}
                    >
                      $
                    </Typography>
                  }
                  disabled={loading}
                  onChange={(e) => {
                    setPaymentAmountValue(parseFloat(e.target.value) || null);
                  }}
                  onBlur={(e) => {
                    const val = parseFloat(e.target.value) || null;
                    const addressToUse = billingAddressSame
                      ? address
                      : billingAddress;
                    handleUpdateCheckout(
                      true,
                      addressToUse,
                      tip,
                      paymentAmount,
                      val,
                    );
                  }}
                  value={paymentAmountValue ?? undefined}
                />
                <FormHelperText>
                  {error?.paymentAmountValue === undefined
                    ? "Enter a custom payment amount."
                    : error.paymentAmountValue}
                </FormHelperText>
              </FormControl>
            </Box>
          )}
        </RadioGroup>
      </Box>
      <FormControl error={error.tipAmount !== undefined}>
        <FormLabel>Tip</FormLabel>
        <Input
          placeholder="Tip Amount"
          type="number"
          startDecorator={
            <Typography
              sx={{
                mr: 1,
              }}
            >
              $
            </Typography>
          }
          disabled={loading}
          onChange={(e) => {
            // remove error on change
            const errors: Record<string, string> = {};
            Object.entries(error).forEach((entry) => {
              if (entry[0] === "tipAmount") {
                return;
              }
              errors[entry[0] as string] = entry[1];
            });
            setError(errors);
            setTip(parseFloat(e.target.value) || null);
          }}
          onBlur={(e) => {
            const val = parseFloat(e.target.value) || null;
            const addressToUse = billingAddressSame ? address : billingAddress;
            handleUpdateCheckout(
              true,
              addressToUse,
              val,
              paymentAmount,
              paymentAmountValue,
            );
          }}
          value={tip ?? undefined}
        />
        <FormHelperText>
          {error?.tipAmount === undefined
            ? "Enter an optional tip. This tip goes entirely to the delivery crew and can always be changed later."
            : error.tipAmount}
        </FormHelperText>
      </FormControl>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        mt={2}
      >
        <Button
          aria-label={"Pay"}
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
          sx={{
            backgroundColor: "#7fce2b",
            "&:hover": {
              backgroundColor: "#3fa624",
            },
            py: 2,
            px: 3,
            borderRadius: 2,
            "&:disabled": {
              bgcolor: "grey.300",
              color: "common.white",
            },
          }}
        >
          {!loading ? `Pay $${amountCharged}` : <CircularProgress size={24} />}
        </Button>
      </Box>
      {error?.status && <Typography color="danger">{error?.status}</Typography>}
    </Box>
  );
};

export default PaymentInfo;
