import { Container, Typography } from "@mui/joy";
import React from "react";
import SEO from "../../components/SEO";

const ContactPage = () => {
  return (
    <Container
      sx={{
        marginTop: "15px",
        textAlign: "center",
      }}
    >
      <SEO title={'Central Arkansas Inflatables - Contact Us'} description={'Contact us to rent an inflatable bounce house near you! Proudly serving Little Rock, Benton, Bryant, Sherwood, Maumelle, and more! '} name={'CentralArkansasInflatables'} type={''} />
      <Typography level={"h1"} textColor={"orange"}>
        Contact Us
      </Typography>

      <Typography fontSize={"21px"} lineHeight={"1.4"} fontWeight={"300"}>
        Looking to get your party started with an inflatable Bounce House or
        have a rental question? <strong>We have you covered!</strong>
        <br />
        Don't hesitate to give us a call or send us an email with any questions
        you may have and we'll be happy to help!
        <br />
        <br />
        Our average response time during business hours is:{" "}
        <strong>9 minutes</strong>.
      </Typography>
      <br />
      <Typography level={"h4"}>(501) 779-8433</Typography>
      <br />
      <Typography level={"h4"}>tre@centralarkansasinflatables.com</Typography>
    </Container>
  );
};

export default ContactPage;
