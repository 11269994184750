import React, { useMemo, useState } from "react";
import {
  Autocomplete,
  AutocompleteOption,
  Box,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Link,
  ListItemContent,
  ListItemDecorator,
  styled,
  Typography,
} from "@mui/joy";
import FacebookIcon from "@mui/icons-material/Facebook";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import NavLinks from "./NavLinks";
import { useSelector } from "react-redux";
import { getProducts } from "../reducers/products";
import { useNavigate } from "react-router-dom";
import type { ProductFromBackend } from "../types";
import { ShoppingCart } from "@mui/icons-material";
import CartBadge from "./CartBadge";
import Logo from "./Logo";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getCartItems } from "../reducers/cart";

const NavBarContainer = styled(Box)({
  alignItems: "center",
  padding: "10px 20px",
  backgroundColor: "#0071BF",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  zIndex: 50,
  position: "sticky",
  top: 0,
});

const PhoneNumber = styled(Typography)({
  fontSize: "24px",
  color: "white",
  float: "left",
  fontWeight: "bold",
});

const Navbar = () => {
  const navigate = useNavigate();
  const cartItems = useSelector(getCartItems);
  const [menuOpen, setMenuOpen] = useState(false);
  const products = useSelector(getProducts);

  const cartCount = useMemo(() => {
    let cartCount = 0;
    for (const cartItem of cartItems) {
      cartCount += cartItem.quantity;
    }
    return cartCount;
  }, [cartItems]);

  return (
    <NavBarContainer>
      <Container>
        <Grid container alignItems="center" spacing={2}>
          {/* Logo */}
          <Grid
            xs={12}
            md={4}
            sx={{ textAlign: { xs: "center", md: "flex-start" } }}
          >
            <Link href={"/"} aria-label={"Central Arkansas Inflatables home"}>
              <Logo size={"small"} />
            </Link>
          </Grid>

          {/* Hamburger Icon (Visible only on mobile) */}
          <Grid xs={2} md={0}>
            <IconButton
              aria-label={"Menu"}
              id={"menu"}
              sx={{ display: { xs: "block", md: "none" } }}
              onClick={() => setMenuOpen(true)}
            >
              <MenuIcon
                aria-labelledby={"menu"}
                color={"inherit"}
                sx={{
                  fontSize: 34,
                  color: "white",
                }}
              />
            </IconButton>
            <Drawer
              anchor="left"
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
            >
              <NavLinks isMobile={true} cartCount={cartCount} />
            </Drawer>
          </Grid>

          {/* Search Bar */}
          <Grid xs={8} md={4}>
            <Autocomplete
              options={products} // You can provide your list of items here
              placeholder={"Search Items"}
              freeSolo
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option?.name
              }
              onChange={(event, newValue) => {
                if (!newValue) {
                  return;
                }
                if (typeof newValue !== "string") {
                  navigate(`/product/${newValue?.id}`);
                }
              }}
              endDecorator={
                <IconButton aria-label={"Search"} id={"search"}>
                  <SearchIcon aria-labelledby={"search"} />
                </IconButton>
              }
              renderOption={(props, product: ProductFromBackend) => (
                <AutocompleteOption {...props}>
                  <ListItemDecorator>
                    <LazyLoadImage
                      width={"20"}
                      height={"20"}
                      style={{
                        height: "auto",
                      }}
                      src={product.heroImage}
                      alt={product.name}
                    />
                  </ListItemDecorator>
                  <ListItemContent sx={{ fontSize: "sm" }}>
                    {product.name}
                    <Typography level="body-xs">
                      {`$${product.price}`}
                    </Typography>
                  </ListItemContent>
                </AutocompleteOption>
              )}
            />
          </Grid>

          <Grid
            xs={2}
            md={0}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            <IconButton aria-label={"Checkout"} id={"cart-checkout"}>
              <Link href={"/cart"} aria-labelledby={"cart-checkout"}>
                <ShoppingCart
                  aria-labelledby={"cart-checkout"}
                  fontSize={"inherit"}
                  sx={{
                    color: "white",
                    "&:hover": {
                      opacity: "0.75",
                    },
                    fontSize: 34,
                  }}
                />
                <CartBadge count={cartCount} />
              </Link>
            </IconButton>
          </Grid>

          {/* Phone Number */}
          <Grid
            xs={0}
            md={3}
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Link
              aria-label={"Go to Central Arkansas Inflatables Facebook"}
              href={"https://www.facebook.com/CentralArkansasInflatables"}
              target={"_blank"}
              rel={"noopener"}
              underline={"none"}
            >
              <IconButton id={"facebook-icon-nav"} aria-label={"Facebook"}>
                <FacebookIcon
                  aria-labelledby={"facebook-icon-nav"}
                  fontSize={"inherit"}
                  sx={{ color: "red", fontSize: 36 }}
                />
              </IconButton>
            </Link>

            <Divider orientation="vertical" />
            <Link
              href={"tel:501-779-8433"}
              underline={"none"}
              aria-label={"Call Central Arkansas Inflatables"}
            >
              <IconButton aria-label={"Call"} id={"phone-icon"}>
                <LocalPhoneIcon
                  aria-labelledby={"phone-icon"}
                  fontSize={"inherit"}
                  sx={{ color: "red", fontSize: 36 }}
                />
              </IconButton>
            </Link>

            <Link
              href={"tel:501-779-8433"}
              underline={"none"}
              aria-label={"Call Central Arkansas Inflatables"}
            >
              <PhoneNumber>501-779-8433</PhoneNumber>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <NavLinks isMobile={false} cartCount={cartCount} />
      </Box>
    </NavBarContainer>
  );
};
export default Navbar;
