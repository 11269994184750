import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";

type LogoProps = {
  size: "small" | "medium" | "large";
};

const SizeMap = {
  small: {
    width: "180px",
    height: "120px",
  },
  medium: {
    width: "240px",
    height: "160px",
  },
  large: {
    width: "480px",
    height: "320px",
  },
};

const Logo = (props: LogoProps) => {
  const width = SizeMap[props.size].width;
  const height = SizeMap[props.size].height;
  return (
    <LazyLoadImage
      width={"490"}
      height={"330"}
      style={{
        width: width,
        height: height,
      }}
      src={`https://centralarkansasinflatables.com/cdn-cgi/imagedelivery/6QiASA1pHsoYecw9egSmhw/381ff54a-dcbe-4fea-c5c4-f6210e66fd00/w=490,h=330`}
      alt="Central Arkansas Inflatables"
    />
  );
};

export default Logo;
