import { Box, Container, Select, Typography } from "@mui/joy";
import DatePicker from "react-datepicker";
import Option from "@mui/joy/Option";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchProductAvailability, setDate } from "../../reducers/actions";

type DateSelector = {
  onSubmit: (startDate: Date, endTime: Date) => void;
  onChangeMonth?: (month: number, year: number) => void;
  blockedDays?: Date[];
  mobile: boolean;
};

function getTimeRanges(
  startHour: number,
  interval: number,
  startDate: Date,
  blockedDays: Date[] = [],
  start?: boolean,
) {
  const ranges = [];
  const date = new Date(startDate);

  const format: any = {
    hour: "numeric",
    minute: "numeric",
  };

  const endHour = (start ? 8 : 10) + 12;
  let blockedBetween = false;

  for (
    let minutes = startHour * 60;
    minutes < endHour * 60;
    minutes = minutes + interval
  ) {
    date.setHours(0);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const time = date.toLocaleTimeString("english", format);
    let unavailable =
      blockedBetween ||
      blockedDays
        .map((item) => item.toISOString())
        .includes(date.toISOString());
    
    if (
      unavailable &&
      !blockedBetween &&
      startDate !== null &&
      startDate.getTime() < date.getTime()
    ) {
      blockedBetween = true;
    }
    ranges.push(
      <Option disabled={unavailable} value={time}>{`${time}${
        unavailable ? ` (Unavailable)` : ""
      }`}</Option>,
    );
  }

  return ranges;
}

const DateSelector = (props: DateSelector) => {
  const dispatch = useDispatch<any>();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<boolean>(false); // this is used to keep track of the end date when the user is selecting the start date for a multi day event
  const [showEndDate] = useState<boolean>(false);

  const onSubmitMeta = (startDate: Date, endTime: Date) => {
    dispatch(fetchProductAvailability(startDate, endTime));
    dispatch(setDate(startDate, endTime));
    props.onSubmit(startDate, endTime);
  };

  const filterPassedTime = (time: Date, start: boolean) => {
    if (props.blockedDays?.includes(time)) {
      return false;
    }
    if (!start) {
      if (startDate && time.getDate() === startDate.getDate()) {
        return time.getHours() > startDate.getHours() && time.getHours() < 22;
      }
    }
    return time.getHours() > 8 && time.getHours() < 22;
  };

  const formatTimeValue = (time: Date | null): string | null => {
    if (time === null) {
      return null;
    }
    const value = time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

    if (props.blockedDays?.includes(time)) {
      return `${value} (Unavailable)`;
    }
    return value;
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      {showEndDate ? (
        <div>
          <Typography component={"h1"}>
            Party
            {startDate
              ? `: Starting ${startDate.toDateString()}`
              : "Date: Select a Date Below!"}
            {endDate
              ? ` To ${endDate.toDateString()}`
              : " To: Select a Date Below!"}
          </Typography>
        </div>
      ) : (
        <div>
          <Typography component={"h1"}>
            Party Date:{" "}
            {startDate ? startDate.toDateString() : "Select a Date Below!"}
          </Typography>
        </div>
      )}
      <Typography component={"p"}>
        {startDate
          ? `From ${startDate.toLocaleTimeString("en-us", {
              hour: "2-digit",
              minute: "2-digit",
            })}`
          : ""}
        {endDate
          ? ` to ${endDate.toLocaleTimeString("en-us", {
              hour: "2-digit",
              minute: "2-digit",
            })}`
          : ""}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: props.mobile ? "column" : "row",
          justifyContent: "center",
          alignItems: "top",
          marginBottom: "10px",
        }}
      >
        <DatePicker
          peekNextMonth={false}
          selected={startDate}
          showPreviousMonths={false}
          selectsRange={showEndDate}
          onMonthChange={(date) => {
            if (props.onChangeMonth !== undefined) {
              props.onChangeMonth(date.getMonth(), date?.getFullYear() || 0);
            }
          }}
          onChange={(dateVal: Date | [Date | null, Date | null]) => {
            let start = dateVal instanceof Date ? dateVal : dateVal[0];
            let end = dateVal instanceof Date ? dateVal : dateVal[1];
            start = start ? new Date(start) : null;
            end = end ? new Date(end) : null;
            start?.setSeconds(0);
            start?.setMilliseconds(0);
            if (dateVal instanceof Date && endDate !== null) {
              end?.setHours(endDate.getHours());
              end?.setMinutes(endDate.getMinutes());
            }
            end?.setSeconds(0);
            end?.setMilliseconds(0);

            setStartDate(start);
            setEndDate(end);

            if (start && end && selectedEndDate) {
              onSubmitMeta(new Date(start), new Date(end));
            }
          }}
          excludeDates={props.blockedDays}
          excludeTimes={props.blockedDays?.filter((item) => {
            return item.toDateString() === startDate?.toDateString();
          })}
          selectsStart
          startDate={startDate}
          endDate={showEndDate ? endDate : null}
          filterDate={(date) => {
            return !props.blockedDays?.includes(date) && date.getTime() + 1000 * 60 * 60 * 24 >= new Date().getTime();
          }}
          filterTime={(date) => filterPassedTime(date, true)}
          showTimeSelect={!props.mobile}
          timeFormat="h:mm aa"
          timeIntervals={30}
          timeCaption="Start"
          dateFormat="MMM d, yyyy h:mm aa"
          inline
        >
          {/*{!showEndDate && (*/}
          {/*  <Button*/}
          {/*    onClick={() => {*/}
          {/*      setEndDate(null);*/}
          {/*      setShowEndDate(true);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    Multi Day*/}
          {/*  </Button>*/}
          {/*)}*/}
        </DatePicker>
        {props.mobile ? (
          <Box>
            {startDate !== null && (
              <Box>
                <Typography level={"h2"}>Select a Start Time</Typography>
                <Select
                  value={formatTimeValue(startDate)}
                  onChange={(event, item: string | null) => {
                    if (item === null) {
                      startDate?.setHours(0);
                      startDate?.setMinutes(0);
                      return;
                    }
                    const newDate = new Date(startDate);
                    const timeSplit = item.split(":");
                    newDate.setHours(
                      parseInt(timeSplit[0]) +
                        (timeSplit[0] !== "12" && timeSplit[1].includes("PM")
                          ? 12
                          : 0),
                    );
                    newDate.setMinutes(parseInt(timeSplit[1]));
                    setStartDate(newDate);
                    if (newDate && endDate && selectedEndDate) {
                      onSubmitMeta(new Date(newDate), new Date(endDate));
                    }
                  }}
                >
                  {getTimeRanges(8, 30, startDate, props.blockedDays, true)}
                </Select>
              </Box>
            )}

            {startDate !== null && filterPassedTime(startDate, true) && (
              <Box>
                <Typography level={"h2"}>Select a End Time</Typography>
                <Select
                  value={formatTimeValue(endDate)}
                  onChange={(event, item: string | null) => {
                    if (item === null) {
                      endDate?.setHours(0);
                      endDate?.setMinutes(0);
                      return;
                    }
                    setSelectedEndDate(true);

                    const newDate = new Date(endDate || startDate);
                    const timeSplit = item.split(":");
                    newDate.setHours(
                      parseInt(timeSplit[0]) +
                        (timeSplit[0] !== "12" && timeSplit[1].includes("PM")
                          ? 12
                          : 0),
                    );
                    newDate.setMinutes(parseInt(timeSplit[1].split(" ")[0]));
                    setEndDate(newDate);
                    if (newDate && startDate) {
                      onSubmitMeta(new Date(startDate), new Date(newDate));
                    }
                  }}
                >
                  {getTimeRanges(
                    Math.max(8, startDate.getHours()) + 0.5,
                    30,
                    startDate,
                    props.blockedDays,
                  )}
                </Select>
              </Box>
            )}
          </Box>
        ) : (
          <>
            <DatePicker
              selected={endDate}
              onChange={(date) => {
                setSelectedEndDate(true);
                date?.setSeconds(0);
                date?.setMilliseconds(0);
                setEndDate(date);
                if (startDate && date) {
                  onSubmitMeta(startDate, date);
                }
              }}
              fixedHeight={true}
              selectsEnd
              startDate={startDate}
              excludeDates={props.blockedDays}
              excludeTimes={props.blockedDays?.filter((item) => {
                return item.toDateString() === startDate?.toDateString();
              })}
              endDate={endDate}
              showTimeSelectOnly={true}
              minDate={startDate}
              showTimeSelect={true}
              filterTime={(date) => filterPassedTime(date, false)}
              timeFormat="h:mm aa"
              timeIntervals={30}
              timeCaption="End"
              dateFormat="MMM d, yyyy h:mm aa"
              inline
            />
          </>
        )}
      </Box>
    </Container>
  );
};

export default DateSelector;
