import type {State} from "./types";
import { SET_SHOPPING_ID, SET_DAMAGE_WAIVER, SET_SETUP_SURFACE } from "./types";
import { combineReducers } from "redux";

const shoppingId = (state: string | null = null, action: any) => {
  switch (action.type) {
    case SET_SHOPPING_ID:
      return action.shoppingId;
    default:
      return state;
  }
};

const damageWaiver = (
  state: { damageWaiver: boolean; damageWaiverPercentage: number } = {
    damageWaiver: false,
    damageWaiverPercentage: 0,
  },
  action: any,
) => {
  switch (action.type) {
    case SET_DAMAGE_WAIVER:
      return action.damageWaiver;
    default:
      return state;
  }
};

const setupSurface = (state: string[] = [], action: any) => {
  switch (action.type) {
    case SET_SETUP_SURFACE:
      return action.setupSurface;
    default:
      return state;
  }
};

export const getSetupSurface = (state: State) => state.customer.setupSurface;

export const getShoppingId = (state: State) => state.customer.shoppingId;

export const getDamageWaiver = (state: State) => state.customer.damageWaiver;

export default combineReducers({
  shoppingId,
  damageWaiver,
  setupSurface,
});
