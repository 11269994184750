import {combineReducers} from "redux";
import products from "./products";
import date from "./date";
import customer from "./customer";
import {cartReducer} from "./cart";


export default combineReducers({
    products,
    customer,
    date,
    cart: cartReducer
})
