import React from "react";
import { Box, Container } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import DateSelector from "../components/DateSelector";
import SEO from "../components/SEO";

const OrderByDatePage = () => {
  const navigate = useNavigate();
  return (
    <Container
      sx={{
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
        <SEO
            title={`Central Arkansas Inflatables - Order By Date`}
            description="Start your Order by selecting a date."
            name="CentralArkansasInflatables"
            type=""
        />
      <Box
        sx={{
          display: { xs: "none", lg: "block" },
        }}
      >
        <DateSelector
          mobile={false}
          blockedDays={[new Date(), new Date(Date.now() + 1000 * 60 * 60 * 24), new Date(2024, 4, 19)]}
          onSubmit={() => {
            navigate("/category");
          }}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "block", lg: "none" },
        }}
      >
        <DateSelector
          mobile={true}
          blockedDays={[new Date(), new Date(Date.now() + 1000 * 60 * 60 * 24), new Date(2024, 4, 19)]}
          onSubmit={() => {
            navigate("/category");
          }}
        />
      </Box>
    </Container>
  );
};

export default OrderByDatePage;
