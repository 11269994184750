import ReactGA from "react-ga4";

export const addPaymentInfo = (
  value: number,
  items: { item_name: string; price: number; quantity: number }[],
) => {
  ReactGA.event("add_payment_info", {
    currency: "USD",
    value: value,
    payment_type: "Credit Card",
    items: items,
  });
};

export const addToCart = (
  value: number,
  items: { item_name: string; price: number; quantity: number }[],
) => {
  ReactGA.event("add_to_cart", {
    currency: "USD",
    value: value,
    items: items,
  });
};

export const beginCheckout = (
  value: number,
  items: { item_name: string; price: number; quantity: number }[],
) => {
  ReactGA.event("begin_checkout", {
    currency: "USD",
    value: value,
    items: items,
  });
};

export const purchase = (
  value: number,
  transaction_id: string,
  items: { item_name: string; price: number; quantity: number }[],
) => {
  ReactGA.event("purchase", {
    currency: "USD",
    value: value,
    transaction_id,
    items: items,
  });
};

export const removeFromCart = (item_name: string) => {
  ReactGA.event("remove_from_cart", {
    item_name,
  });
};

export const viewItem = (item_name: string, price: number, item_category: string) => {
  ReactGA.event("view_item", {
    currency: "USD",
    value: price,
    items: [
      {
        item_name,
        price,
        item_list_name: item_category,
      },
    ],
  });
};

export const viewItemList = (
  item_name: string,
  price: number,
  item_category: string,
) => {
  ReactGA.event("view_item_list", {
    currency: "USD",
    value: price,
    items: [
      {
        item_name,
        price,
        item_list_name: item_category,
      },
    ],
  });
};
