import React from "react";

import { AspectRatio, Box, Link, Skeleton, Typography } from "@mui/joy";
import type { ProductFromBackend } from "../types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

type ProductCarouselProps = {
  products: ProductFromBackend[];
  loading: boolean;
  textColor?: string;
  children?: React.ReactNode | React.ReactNode[];
};

function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        zIndex: 1,
        right: "-50px",
        top: "50%",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          border: "1px solid #4D5559",
          width: "36px",
          height: "36px",
          borderRadius: "50%",
          textColor: "#4D5559",
        }}
      >
        <ChevronRight
          fontSize={"medium"}
          style={{
            color: "#4D5559",
          }}
          sx={{ color: "#4D5559" }}
        />
      </Box>
    </Box>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        zIndex: 1,
        top: "50%",
        left: "-50px",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          border: "1px solid #4D5559",
          width: "36px",
          height: "36px",
          borderRadius: "50%",
          textColor: "#4D5559",
        }}
      >
        <ChevronLeft
          fontSize={"medium"}
          style={{
            color: "#4D5559",
          }}
          sx={{ color: "#4D5559" }}
        />
      </Box>
    </Box>
  );
}

function ProductCarousel(props: ProductCarouselProps) {
  let products: (
    | ProductFromBackend
    | { id: number; heroImage?: string; name?: string; price?: number, slug?: string }
  )[] = props.products;
  if (props.loading && props.products.length === 0) {
    products = [
      {
        id: -1,
      },
      {
        id: -1,
      },
      {
        id: -1,
      },
      {
        id: -1,
      },
    ];
  }
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {products?.map((product, index) => {
        return (
          <Box
            key={"product-carousel-box-" + index}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Link
              aria-label={`Shop for ${product.name}`}
              key={`link-product-car-${product.id}-${index}`}
              href={`/product/${product.id}/${product?.slug || ""}/`}
              display={"flex"}
              flexDirection={"column"}
              sx={{
                borderRadius: "5px",
                cursor: "pointer",
                marginRight: "15px",
              }}
            >
              {!props.loading && (
                <div
                  key={`${index}-product-wrapper`}
                  style={{
                    height: "150px",
                    width: "150px",
                    backgroundColor: "white",
                  }}
                >
                  <AspectRatio
                    key={`${index}-product-aspect-ra`}
                    minHeight={"120px"}
                    maxHeight={"150px"}
                    ratio={"1/1"}
                  >
                    <LazyLoadImage
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                      }}
                      width={"150"}
                      height={"150"}
                      key={`${index}-product`}
                      src={product?.heroImage || ""}
                      alt={product?.name || ""}
                    />
                  </AspectRatio>
                </div>
              )}
              {props.loading && (
                <div
                  key={`${index}-product-wrapper`}
                  style={{
                    height: "150px",
                    width: "150px",
                    backgroundColor: "white",
                  }}
                >
                  <Skeleton
                    variant={"rectangular"}
                    width={"150px"}
                    height={"150px"}
                  />
                </div>
              )}

              <Typography
                key={`link-product-car-${product.id}-${index}-h1`}
                textColor={props?.textColor || "white"}
              >
                <Skeleton
                  key={`skeleton-loader-${index}`}
                  loading={props.loading}
                  overlay={true}
                  variant={"text"}
                />
                  {product?.name}
              </Typography>
              {!props.loading && (
                <Typography
                  key={`link-product-car-${product.id}-${index}-price`}
                  textColor={props?.textColor || "white"}
                >{`$${product?.price
                  ?.toString()
                  ?.replace(".00", "")}`}</Typography>
              )}
            </Link>
          </Box>
        );
      })}
    </Slider>
  );
}

export default ProductCarousel;
