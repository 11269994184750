import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import type { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useSearchParams } from "react-router-dom";
import PaymentStatus from "../../components/PaymentStatus";

let stripePromise: Promise<Stripe | null> | undefined;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      process.env.REACT_APP_NEXT_PUBLIC_STRIPE_KEY || "",
    );
  }
  return stripePromise;
};
const PaymentFinalizedPage = () => {
  const [searchParams] = useSearchParams();

  return (
    <Elements
      stripe={getStripe()}
      options={{
        clientSecret:
          searchParams.get("payment_intent_client_secret") || undefined,
      }}
    >
      <PaymentStatus />
    </Elements>
  );
};

export default PaymentFinalizedPage;
