import { Divider, Grid, IconButton, Link, styled, Typography } from "@mui/joy";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Logo from "./Logo";

const ItemContainer = styled("div")`
  display: none;

  @media only screen and (max-width: 768px) {
    display: flex;
  }
  flex-direction: row;
  justify-content: space-evenly;
`;

const Footer = () => {
  const links = [
    { label: "Home", href: "/" },
    { label: "Categories", href: "/category/" },
    { label: "FAQ", href: "/faq" },
    { label: "Contact Us", href: "/contact" },
    { label: "Privacy Policy", href: "/privacy" },
      { label: "Terms of Service", href: "/tos" },
    { label: "Cancellation Policy", href: "/cancellation" },
  ];
  return (
    <Grid
      container
      sx={{
        backgroundColor: "#0071BF",
        textAlign: "center",
      }}
    >
      <Grid
        xs={12}
        md={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Logo size={"medium"} />
        <ItemContainer>
          <IconButton
            id={"facebook-icon"}
            aria-label={"Facebook"}
            sx={{
              "&:hover": {
                color: "black",
              },
            }}
          >
            <FacebookIcon
              aria-labelledby={"facebook-icon"}
              fontSize={"inherit"}
              sx={{
                color: "white",
                fontSize: 48,
                "&:hover": {
                  color: "black",
                },
              }}
              onClick={() => {
                window.open(
                  "https://www.facebook.com/CentralArkansasInflatables",
                  "_blank",
                );
              }}
            />
          </IconButton>
          <Divider orientation="vertical" />
          <Link
            href={"tel:501-779-8433"}
            underline={"none"}
            aria-label={"Call Central Arkansas Inflatables"}
          >
            <IconButton aria-label={"Call"} id={"phone-icon"}>
              <LocalPhoneIcon
                aria-labelledby={"phone-icon"}
                fontSize={"inherit"}
                sx={{ color: "white", fontSize: 48 }}
              />
            </IconButton>
          </Link>
        </ItemContainer>
        <Link
          aria-label={"Visit Central Arkansas Inflatables Facebook"}
          sx={{ display: { xs: "none", md: "block" } }}
          href={"https://www.facebook.com/CentralArkansasInflatables"}
          target={"_blank"}
          rel={"noopener"}
        >
          <IconButton
            aria-label={"Facebook"}
            id={"facebook-icon-2"}
            sx={{
              "&:hover": {
                color: "black",
              },
            }}
          >
            <FacebookIcon
              aria-labelledby={"facebook-icon-2"}
              fontSize={"inherit"}
              sx={{
                color: "white",
                fontSize: 36,
                "&:hover": {
                  color: "black",
                },
              }}
            />
          </IconButton>
        </Link>
      </Grid>
      <Grid xs={12} md={4}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            textColor={"white"}
            sx={{
              fontSize: "2rem",
              fontWeight: "bold",
              font: "Roboto",
            }}
          >
            Menu
          </Typography>
          {links.map((link) => (
            <Link
              key={link.label}
              href={link.href}
              aria-label={`Go to ${link.label}`}
            >
              <Typography
                textColor={"white"}
                sx={{
                  fontSize: "1rem",
                  "&:hover": {
                    color: "black",
                  },
                  font: "Roboto",
                }}
              >
                {link.label}
              </Typography>
            </Link>
          ))}
        </div>
      </Grid>
      <Grid
        xs={0}
        md={4}
        sx={{
          display: { xs: "none", md: "block" },
        }}
      >
        <Typography
          textColor={"white"}
          sx={{
            fontSize: "2rem",
            fontWeight: "bold",
            font: "Roboto",
          }}
        >
          Contact Us
        </Typography>
        <Link
          href={"tel:501-779-8433"}
          aria-label={"Call Central Arkansas Inflatables"}
        >
          <Typography
            textColor={"white"}
            sx={{
              fontSize: "1.5rem",
              "&:hover": {
                color: "black",
              },
              font: "Roboto",
            }}
          >
            (501) 779-8433
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default Footer;
