import React, { useEffect, useState } from "react";
import type { RouteObject } from "react-router";
import MainLayout from "./layout/MainLayout";
import Home from "./pages/Home";
import NotFoundPage from "./pages/NotFoundPage";
import CategoryShopping from "./pages/CategoryShopping";
import ProductPage from "./pages/ProductPage";
import OrderByDatePage from "./pages/OrderByDatePage";
import CategoryList from "./pages/CategoryList";
import CartPage from "./pages/CartPage";
import ReturnPage from "./pages/ReturnPage";
import PrivacyPage from "./pages/standard/Privacy";
import FAQ from "./pages/standard/FAQ";
import ContactPage from "./pages/standard/Contact";
import Rentals from "./pages/Rentals";
import PaymentFinalizedPage from "./pages/cart/PaymentFinalizedPage";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCategory, getProduct, isProductLoading } from "./reducers/products";
import CancellationPage from "./pages/CancellationPage";
import TOS from "./pages/standard/TOS";

function ProductPageWrapper() {
  const [productSlug, setProductSlug] = useState("");
  const { productId } = useParams();
  const router = useNavigate();

  const product = useSelector(getProduct(productId || ""));
  const loading = useSelector(isProductLoading);
  useEffect(() => {
    if (loading) {
      return;
    }
    if (product === undefined) {
      router("/rentals");
      return;
    }
    setProductSlug(product.slug);
  }, [product, loading]);

  return (
    <Navigate
        replace={true}
      to={{

        pathname: `/product/${productId}/${productSlug}`,
      }}
    />
  );
}


function CategoryPageWrapper() {
    const [slug, setCategorySlug] = useState('');
    const { categoryId } = useParams();
    const router = useNavigate();

    const category = useSelector(getCategory(categoryId || ""));
    const loading = useSelector(isProductLoading);
    useEffect(() => {
        if (loading) {
            return;
        }
        if (category === undefined)  {
            router('/categories');
            return
        }
        setCategorySlug(category.slug);
    }, [category, loading]);

    return (
        <Navigate
            replace={true}
            to={{
                pathname: `/category/${categoryId}/${slug}`,
            }}
        />
    );
}

//  * HOME PAGE

const routes: RouteObject[] = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: "/cart/",
                element: <CartPage />,
            },
            {
                path: "/cancellation/",
                element: <CancellationPage />,
            },
            {
                path: "/product/:productId",
                element: <ProductPageWrapper />
            },
            {
                path: "/product/:productId/:productSlug",
                element: <ProductPage />,
            },
            {
                path: "/categories/",
                element: <Navigate
                    replace={true}
                    to={{
                        pathname: `/category/`,
                    }}
                />
            },
            {
                path: "/category/",
                element: <CategoryList />,
            },
            {
                path: "/category/:categoryId",
                element: <CategoryPageWrapper />
            },
            {
              path: "/category/:categoryId/:categorySlug/:location?",
                element: <CategoryShopping />,
            },
            {
                path: "/order-by-date",
                element: <OrderByDatePage />
            },
            {
                path: "/return/",
                element: <ReturnPage />
            },
            {
                path: "/items/",
                element:<Navigate
                    replace={true}
                    to={{
                        pathname: `/rentals/`,
                    }}
                />
            },
            {
                path: "/store/",
                element: <Navigate
                    replace={true}
                    to={{
                        pathname: `/rentals/`,
                    }}
                />
            },
            {
                path: "/product/",
                element: <Navigate
                    replace={true}
                    to={{
                        pathname: `/rentals/`,
                    }}
                />
            },
            {
                path: "/products/",
                element: <Navigate
                    replace={true}
                    to={{
                        pathname: `/rentals/`,
                    }}
                />
            },
            {
                path: "/rentals/",
                element: <Rentals />
            },
            {
                path: "/faq/",
                element: <FAQ />,
            },
            {
                path: "/privacy/",
                element: <PrivacyPage />,
            },
            {
                path: "/tos/",
                element: <TOS />,
            },
            {
                path: "/contact/",
                element: <ContactPage />,
            },
            {
              path: "/finalized/",
              element: <PaymentFinalizedPage />
            },
            {
                index: true,
                path: "/", // yes, again
                element: <Home />
            },
            {
                path: '*',
                element: <NotFoundPage />,
            }
        ],
    },
];

export default routes;
