import React from "react";
import { Table } from "@mui/joy";
import {getCurrencyString} from "../../pages/CartPage";

type ItemExtender<T> = {
  name: string;
  value: T | undefined;
};

type ItemListProps<T extends ItemExtender<string | number | boolean>> = {
  items: T[];
};

const SidewaysItemList = <T extends ItemExtender<string | number | boolean>>({
  items,
}: ItemListProps<T>) => {
  return (
    <Table
      sx={{
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none",
          width: "100%",
      }}
    >
        <tbody>
          {items
            .filter((column) => column.value !== undefined)
            .map((column, index) => {
              return (
                <tr key={`row-${index}-side`}>
                  <th style={{
                      backgroundColor: 'transparent',
                      paddingLeft: 0
                  }} key={`header-${column.name}`}>{column.name}</th>
                  {/*{column.middleValue !== undefined && (*/}
                  {/*  <td key={`mid-${column.name}`}>{`$${column.middleValue}`}</td>*/}
                  {/*)}*/}
                  <td style={{
                      border: 'none',
                      display: 'flex',
                        justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginRight: '12px',
                      paddingRight: 0

                  }} key={`data-${column.name}`}>{typeof column?.value === "number" ? getCurrencyString(column.value) : `${column.value}`}</td>
                </tr>
              );
            })}
        </tbody>
    </Table>
  );
};

export default SidewaysItemList;
