import Typography from "@mui/material/Typography";
import React from "react";
import {
  Badge,
  Box,
  IconButton,
  Link,
  ListItem,
  ListItemContent,
  ListItemDecorator,
} from "@mui/joy";
import CloseIcon from "@mui/icons-material/Close";
import { Add, Remove } from "@mui/icons-material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import type { CartItem } from "../types";
import { useDispatch } from "react-redux";
import {removeCartItem, updateCartItemQuantity} from "../reducers/actions";

export default function CartItemElement({
  item,
  editable,
}: {
  item: CartItem;
  editable?: boolean;
}) {
  const dispatch = useDispatch<any>();
  const { name, id, image, quantity, price } = item;

  return (
    <ListItem>
      <ListItemDecorator
        sx={{
          mr: 1,
        }}
      >
        {/* Image */}
        <Badge
          badgeContent={quantity}
          invisible={editable}
          color={"neutral"}
          badgeInset={"10%"}
        >
          <Link
            aria-label={`Go to Product Page for ${name}`}
            href={`/product/${id}`}
            marginRight={"12px"}
            sx={{
              width: "100px",
              minWidth: "100px",
            }}
          >
            <LazyLoadImage
              width={"100"}
              height={"100"}
              style={{
                width: "100%",
                height: "100%",
              }}
              src={image}
              alt={name}
            />
          </Link>
        </Badge>
      </ListItemDecorator>
      {/*  Description */}
      <ListItemContent>
        <Typography
          component="p"
          fontSize={"16px"}
          fontWeight={"600"}
          lineHeight={"20px"}
        >
          {name}
        </Typography>

        {editable && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              pt: 2,
              borderTop: "1px solid",
              borderColor: "background.level1",
            }}
          >
            <IconButton
              aria-label={"Decrease Quantity"}
              size="sm"
              variant="outlined"
              onClick={() => {
                  dispatch(updateCartItemQuantity(item.id, quantity - 1))
              }}
            >
              <Remove />
            </IconButton>
            <Typography>{quantity}</Typography>
            <IconButton
              aria-label={"Increase Quantity"}
              size="sm"
              variant="outlined"
              onClick={() => {
                  dispatch(updateCartItemQuantity(item.id, quantity + 1))
              }}
            >
              <Add />
            </IconButton>
          </Box>
        )}
      </ListItemContent>

      {/*  Controls */}
      <Box
        display={"flex"}
        sx={{
          alignItems: "flex-end",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box>
          {editable && (
            <IconButton
              id={"close-icon"}
              aria-label={"Remove Item"}
              onClick={() => {
                dispatch(removeCartItem(item.id));
              }}
              sx={{
                ":hover": {
                  opacity: "0.8", // Ensure this color is defined in your theme
                },

                transition: "colors 0.5s",
                borderRadius: "50%",
              }}
            >
              <CloseIcon
                aria-labelledby={"close-icon"}
                fontSize={"inherit"}
                sx={{
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              />
            </IconButton>
          )}
        </Box>
        <Box margin={"0px 12px 0px 0px"} pt={"1rem"}>
          <Typography
            fontSize={"14px"}
            fontWeight={"400"}
            lineHeight={"28px"}
            letterSpacing={".5px"}
          >
            {`$${price}`}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
}

// import {Product} from "../types";
// import React from "react";
// import {Stack} from "@mui/joy";

// type CartItemProps = {
//     product: Product | undefined;
//     quantity: number;
// }

// export const OldCartItem = (props: CartItemProps) => {
//     if (props.product === undefined) {
//         return <></>;
//     }

//     return <Stack direction="row" spacing={1}>
//         <img src={props.product.fullImage} style={{
//             width: 50,
//             height: 50,
//         }}  alt={props.product.name}/>
//         <div style={{
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//         }}>
//             <div>{props.product.name}</div>
//             <div>{`$${props.product.baseCost}`}</div>
//             <div>{`Quantity: ${props.quantity}`}</div>
//         </div>
//     </Stack>
// }
