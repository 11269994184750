import type {CartItem, Category, ProductFromBackend} from '../types';

export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const UPDATE_PRODUCT_AVAILABILITY = 'UPDATE_PRODUCT_AVAILABILITY';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_SHOPPING_ID = 'SET_SHOPPING_ID';
export const SET_DAMAGE_WAIVER = 'SET_DAMAGE_WAIVER';


export const UPDATE_CART_ITEM = 'cart/UPDATE_CART_ITEM';
export const UPDATE_CART_ITEM_QUANTITY = 'cart/UPDATE_CART_ITEM_QUANTITY';
export const CLEAR_CART = 'cart/CLEAR_CART';
export const SET_SETUP_SURFACE = 'SET_SETUP_SURFACE';

export type Action = {
    type: string;
}

export type UpdateProductAvailability = {
    id: number;
    quantity: number
}


export type ProductsAction = Action & {
    products: {
        products: ProductFromBackend[];
        categories: Category[];
    };
    availability?: UpdateProductAvailability[]
}

export type ProductsState = {
    allProducts: Record<number, ProductFromBackend>;
    displayedProducts: ProductFromBackend[];
    displayedCategories: Category[];
    availability: Record<number, number>;
    allCategories: Record<number, Category>;
    loading: boolean;
}

export type DateRange = {
    startInstant: Date | null;
    endInstant: Date | null;
}

export type DamageWaiverType = {
    damageWaiver: boolean;
    damageWaiverPercentage: number;
}

export type CustomerState = {
    shoppingId: string | null;
    damageWaiver: DamageWaiverType;
    setupSurface: string[];
}


export type CartState = {
    items: CartItem[];
}

export type State = {
    cart: CartState;
    products: ProductsState;
    date: DateRange;
    customer: CustomerState;
}
