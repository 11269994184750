import React from "react";
import Faq from "react-faq-component";
import { Container, Typography } from "@mui/joy";
import SEO from "../../components/SEO";

const data = {
  rows: [
    {
      title: "Where are you located?",
      content: `We are located in Benton Arkansas, but we have free delivery to the surrounding areas such as Little Rock, Haskell, Bryant, and more! Our delivery area is constantly expanding, so if you are unsure if we deliver to you, please contact us!`,
    },
    {
      title: "When do you set up and pick-up?",
      content:
        "We set up during the morning of your event and pick up after the event is over. If you need a specific time, please let us know and we will do our best to accommodate you.",
    },
    {
      title: "What if I don’t have a power source?",
      content:
        "Power must be present within 75 feet of the inflatable. If you do not have power nearby we can try and move the inflatable, but if that is not possible, we will not be able to set up the inflatable.",
    },
    {
      title: "What if we need to cancel?",
      content: "In most cases we will offer a full 100% money-back refund. To see our full cancellation policy, please visit our <a href='https://centralarkansasinflatables.com/cancellation/'>cancellation policy</a> page.",
    },
    {
      title: "Other Questions?",
      content:
        "Please reach out to us via phone or email, and we will be happy to answer any questions you may have!\n\nPhone: (501) 779-8433\nEmail: tre@centralarkansasinflatables.com",
    },
  ],
};

const styles = {
  // bgColor: 'white',
  // titleTextColor: "blue",
  // rowTitleColor: "blue",
  // rowContentColor: 'grey',
  // arrowColor: "red",
};

const config = {
  animate: true,
  // arrowIcon: "V",
  tabFocus: true,
};

const FAQ = () => {
  return (
    <Container
      sx={{
        marginTop: "15px",
        textAlign: "center",
      }}
    >
      <SEO title={'Central Arkansas Inflatables - FAQ'} description={'Learn more about Central Arkansas Inflatables and why we are the premier choice for inflatable rentals!'} name={'CentralArkansasInflatables'} type={''} />

      <Typography level={"h1"} textColor={"orange"}>
        Frequently Asked Questions | FAQs
      </Typography>
      <Faq data={data} styles={styles} config={config} />
    </Container>
  );
};

export default FAQ;
