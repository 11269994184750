import { Container, ListItem, Typography } from "@mui/joy";
import React from "react";
import SEO from "../components/SEO";
import Faq from "react-faq-component";
const data = {
  rows: [
    {
      title: "What is your weather policy?",
      content: 'All cancellations due to weather before the inflatable is delivered will receive a full refund. If you cancel due to weather, you will not be charged.',
    },
    {
      title: "My kid is sick, can I cancel?",
      content: "Yes! If you need to cancel, please contact us as soon as possible. We understand that we run a business primarily kids, and kids get sick. We want all kids to have a great event and if that means that we need to cancel, we will do so. Please see cancellation policy below for more details.",
    },
    {
      title: "We are moving the event, can we reschedule?",
      content: "Absolutely! If you need to reschedule, please contact us as soon as possible. We will do our best to accommodate your new date and time. If we are unable to accommodate your new date and time, we will offer a full refund.",
    },
    {
      title: "When will you not refund or reschedule?",
      content: "The only time we will not refund or reschedule is after the inflatable has been delivered. Once the inflatable has been delivered, we will not refund or reschedule for any reason. This includes weather, sickness, or any other reason. Please see our cancellation policy below for more details.",
    }
  ],
};
const CancellationPage = () => {
  return (
    <Container
      sx={{
        marginTop: "15px",
      }}
    >
      <SEO
        title={"Cancellation Policy"}
        description={
          "Central Arkansas Inflatables weather and cancellation policy"
        }
        name={"CentralArkansasInflatables"}
        type={""}
      />
      <Typography level={"h1"} textColor={"orange"} textAlign={"center"}>
        Cancellation Policy
      </Typography>
      <Typography component={"p"} textAlign={"center"} fontWeight={"600"}>
        No refunds, rain checks, or rescheduling after the inflatable has been
        delivered.
      </Typography>
      <Faq data={data} styles={{}} config={{
        animate: true,
        tabFocus: true,
      }} />
      <br />
      <Typography component={"h2"} paddingTop={"2"}>
        Orders Under $750:
      </Typography>
      <ul>
        <ListItem>
          <Typography>
            100% money-back refund if the event is cancelled before the morning
            of the event <strong>for any reason</strong>.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            A rain check will be issued if the event is cancelled the morning of
            the event before delivery <strong>for any reason</strong>.
          </Typography>
        </ListItem>
      </ul>
      <Typography component={"h2"}>Orders Over $750:</Typography>
      <Typography component={"p"}>
        Orders over $750 that are paid by credit card will be charged a 3%
        processing fee if the order is cancelled. This is to cover the
        processing fees we are charged by the credit card companies.
      </Typography>
      <ul>
        <ListItem>
          <Typography component={"p"}>
            Money-back refund if the event is cancelled before 7 days of the
            event <strong>for any reason</strong>.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            A rain check will be issued if the event is cancelled within 7 days
            of the event <strong>for any reason</strong>.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            Refunds within 7 days of the event are only permitted on a
            case-by-case basis at the discretion of Central Arkansas
            Inflatables.
          </Typography>
        </ListItem>
      </ul>
      <Typography component={"h2"} paddingTop={"2"}>
        Contact Us
      </Typography>
      <Typography component={"p"}>
        If you need to cancel or reschedule or have any questions, please contact us as soon as possible.
        <br />
        Phone: (501) 779-8433
        <br />
        Email: tre@centralarkansasinflatables.com

      </Typography>
    </Container>
  );
};

export default CancellationPage;
