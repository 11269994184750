import {
  Box,
  Container,
  Grid,
  Link,
  List,
  ListDivider,
  ListItem,
  Typography,
} from "@mui/joy";
import CartBanner from "../components/CartBanner";
import CartItem from "../components/CartItem";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getDateRange } from "../reducers/date";
import SidewaysItemList from "../components/SidewaysItemList";
import CheckoutPage from "./cart/CheckoutPage";
import EventInfoPage from "./cart/EventInfoPage";
import type { FormattedPaymentInfoLineItem } from "./cart/EventInfoPage";
import { getDamageWaiver } from "../reducers/customer";
import { Button } from "@mui/material";
import type { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { getCartItems } from "../reducers/cart";
import type { CartItem as CartEntry } from "../types";
import SEO from "../components/SEO";
import { addPaymentInfo, beginCheckout } from "../lib/events";

export type Address = {
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  postal_code: string;
  country: string;
};

export type CartFormData = {
  email: string;
  emailOffers: boolean;
  damageWaiver: boolean;
  firstName: string;
  lastName: string;
  setupSurface: string;
  customerNotes: string;
  phone: string;
};

let stripePromise: Promise<Stripe | null> | undefined;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      process.env.REACT_APP_NEXT_PUBLIC_STRIPE_KEY || "",
    );
  }
  return stripePromise;
};

export const getCurrencyValue = (amount: number): number => {
  return Math.ceil(amount * 100) / 100;
};

export const getCurrencyString = (amount: number): string => {
  return `$${getCurrencyValue(amount).toFixed(2)}`;
};

export const CartPage = () => {
  const { startInstant, endInstant } = useSelector(getDateRange);
  const [clientSecret, setClientSecret] = useState<string | undefined>(
    undefined,
  );
  const [damageWaiver, setDamageWaiver] = useState<boolean>(true);
  const cartItems = useSelector(getCartItems);
  const [step, setStep] = useState<"event_info" | "payment_info">("event_info");
  const [formData, setFormData] = React.useState<CartFormData | undefined>(
    undefined,
  );
  const [totals, setTotals] = useState<
    { totalAmount: number; depositAmount: number } | undefined
  >(undefined);
  const stripe = getStripe();

  const [address, setAddress] = React.useState<Address | undefined>(undefined);
  const damageWaiverSelector = useSelector(getDamageWaiver);
  const damageWaiverEnabled =
    damageWaiverSelector.damageWaiver &&
    damageWaiverSelector.damageWaiverPercentage > 0;

  const { totalPrice, cartCount, cartDetails } = useMemo(() => {
    let totalPrice = 0;
    let cartCount = 0;
    const cartDetails: { [key: string]: CartEntry } = {};
    if (cartItems) {
      cartItems.forEach((item) => {
        totalPrice += item.price * item.quantity;
        cartCount += item.quantity;
        cartDetails[item.id.toString()] = item;
      });
      beginCheckout(
        totalPrice,
        cartItems.map((item) => {
          return {
            item_name: item.name,
            quantity: item.quantity,
            price: item.price,
          };
        }),
      );
    }
    return { totalPrice, cartCount, cartDetails };
  }, [cartItems]);

  const [lineItems, setLineItems] = useState<FormattedPaymentInfoLineItem[]>(
    [],
  );

  const lineItemsToRender = useMemo(() => {
    return lineItems
      .filter((item) => item.name !== "Damage Waiver" || damageWaiver)
      .map((item) => {
        return {
          name: item.name,
          value: item.itemAmount,
        };
      });
  }, [damageWaiver, lineItems]);

  if (stripe === undefined) {
    return (
      <Box
        sx={{
          textAlign: "center",
          border: "1px solid #cccccc",
          borderRadius: "5px",
          marginTop: "10px",
        }}
      >
        <Typography>
          <p>Stripe is not available</p>
        </Typography>
      </Box>
    );
  }

  if (startInstant === undefined || endInstant === undefined) {
    return (
      <Box
        sx={{
          textAlign: "center",
          border: "1px solid #cccccc",
          borderRadius: "5px",
          marginTop: "10px",
        }}
      >
        <Typography>
          <p>You must select a date range</p>
        </Typography>
      </Box>
    );
  }

  if (cartCount === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "24px",
          alignItems: "center",
        }}
      >
        <Typography component="h6">Your cart is empty</Typography>
        <Link underline={"none"} href={"/rentals"} aria-label={"Shop All"}>
          <Button aria-label={"Shop All"} variant="outlined" color={"primary"}>
            Shop All
          </Button>
        </Link>
        <Link
          underline={"none"}
          href={"/category"}
          aria-label={"Shop By Category"}
        >
          <Button
            aria-label={"Shop By Category"}
            variant="outlined"
            color={"secondary"}
          >
            Shop By Category
          </Button>
        </Link>
      </Box>
    );
  }

  const onSubmit = (
    lineItems: FormattedPaymentInfoLineItem[],
    clientSecret: string,
    formData: CartFormData,
    address: Address,
    damageWaiver: boolean,
    totals: { totalAmount: number; depositAmount: number },
  ) => {
    addPaymentInfo(
      totals.totalAmount,
      cartItems.map((item) => {
        return {
          item_name: item.name,
          quantity: item.quantity,
          price: item.price,
        };
      }),
    );
    setStep("payment_info");
    setLineItems(lineItems);
    setClientSecret(clientSecret);
    setFormData(formData);
    setAddress(address);
    setDamageWaiver(damageWaiver);
    setTotals(totals);
  };

  const goBack = () => {
    setStep("event_info");
  };

  return (
    <Container
      disableGutters={true}
      sx={{
        marginTop: 1,
        paddingTop: 2,
        paddingBottom: 2,

        height: "100%",
        minHeight: "100vh",
      }}
    >
      <SEO
        title="Central Arkansas Inflatables - Checkout"
        description="View all Bounce House, Water Slide, and Inflatable Rentals from Central Arkansas Inflatables."
        name="CentralArkansasInflatables"
        type=""
      />
      <Grid
        container
        spacing={{
          xs: 0,
          md: 5,
        }}
        sx={{
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <Grid
          xs={12}
          md={5}
          sx={{
            xs: {
              border: "0px",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            },
            borderLeft: "1px solid",
            borderColor: "divider",
          }}
          order={{ xs: 0, md: 2 }}
        >
          <CartBanner />

          <List>
            {Object.values(cartDetails ?? {}).map((entry: CartEntry) => (
              <CartItem key={entry.id} item={entry} editable={false} />
            ))}
            <ListDivider inset={"gutter"} />

            <ListItem>
              <SidewaysItemList items={lineItemsToRender} />
            </ListItem>

            <ListDivider inset={"gutter"} />

            {totals && (
              <ListItem>
                <SidewaysItemList
                  items={[
                    { name: "Total", value: totals.totalAmount },
                    { name: "Due To Book", value: totals.depositAmount },
                  ]}
                />
              </ListItem>
            )}
            {!totals && (
              <ListItem>
                <SidewaysItemList
                  items={[{ name: "Estimated Total", value: totalPrice }]}
                />
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid xs={11} md={7} order={{ xs: 1, paddingLeft: "1rem" }}>
          {step === "event_info" && (
            <EventInfoPage
              stripe={stripe}
              submit={onSubmit}
              address={address}
              formData={formData}
              updateDamageWaiver={(newVal) => {
                setDamageWaiver(newVal);
              }}
            />
          )}
          {step === "payment_info" && clientSecret && address && formData && (
            <CheckoutPage
              stripe={stripe}
              address={address}
              clientSecret={clientSecret}
              damageWaiverEnabled={damageWaiver}
              damageWaiverAllowed={damageWaiverEnabled}
              formData={formData}
              goBack={goBack}
              depositAmount={totals?.depositAmount || 0}
              totalAmount={totals?.totalAmount || 0}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default CartPage;
