import {
  CLEAR_CART,
  RECEIVE_PRODUCTS,
  SET_DAMAGE_WAIVER,
  SET_END_DATE,
  SET_SETUP_SURFACE,
  SET_SHOPPING_ID,
  SET_START_DATE,
  UPDATE_CART_ITEM,
  UPDATE_CART_ITEM_QUANTITY,
  UPDATE_PRODUCT_AVAILABILITY,
} from "./types";
import { BASE_URL } from "../index";
import type { CartItem } from "../types";
import { addToCart } from "../lib/events";

export const getAllProducts = (data: any) => (dispatch: any) => {
  dispatch({
    type: RECEIVE_PRODUCTS,
    products: data,
  });
};

export const fetchProductAvailability =
  (startTime: Date, endTime: Date) => (dispatch: any) => {
    if (!startTime || !endTime) {
      console.log("No start or end time");
      return;
    }
    fetch(
      `${BASE_URL}/products/availability?startDateISO=${startTime.toISOString()}&endDateISO=${endTime.toISOString()}`,
    )
      .then((a) => a.json())
      .then((a: any) => {
        dispatch({
          type: UPDATE_PRODUCT_AVAILABILITY,
          availability: a?.availability,
        });
      });
  };

export const setShoppingId = (shoppingId: string | null) => (dispatch: any) => {
  dispatch({
    type: SET_SHOPPING_ID,
    shoppingId,
  });
};

export const setDamageWaiver =
  (damageWaiver: boolean, damageWaiverPercentage: number) =>
  (dispatch: any) => {
    dispatch({
      type: SET_DAMAGE_WAIVER,
      damageWaiver: { damageWaiver, damageWaiverPercentage },
    });
  };

export const setSetupSurface = (setupSurface: string[]) => (dispatch: any) => {
  dispatch({
    type: SET_SETUP_SURFACE,
    setupSurface,
  });
};

export const setDate = (startTime: Date, endTime: Date) => (dispatch: any) => {
  dispatch({
    type: SET_START_DATE,
    startInstant: startTime,
  });
  dispatch({
    type: SET_END_DATE,
    endInstant: endTime,
  });
};

export const updateCartItem = (item: CartItem) => (dispatch: any) => {
  addToCart(item.price, [
    {
      item_name: item.name,
      price: item.price,
      quantity: item.quantity,
    },
  ]);
  dispatch({ type: UPDATE_CART_ITEM, item });
};

export const updateCartItemQuantity = (product: number, quantity: number) => ({
  type: UPDATE_CART_ITEM_QUANTITY,
  productId: product,
  quantity,
});

export const removeCartItem = (productId: number) => ({
  type: UPDATE_CART_ITEM_QUANTITY,
  productId: productId,
  quantity: 0,
});

export const clearCart = () => ({
  type: CLEAR_CART,
});
