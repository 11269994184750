import {
  Box,
  Breadcrumbs,
  Button,
  Link,
  List,
  ListDivider,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from "@mui/joy";

import React from "react";
import PaymentInfo from "../../components/PaymentInfo";
import type { Address, CartFormData } from "../CartPage";
import { Elements } from "@stripe/react-stripe-js";
import { ChevronRight } from "@mui/icons-material";
import type { Stripe } from "@stripe/stripe-js";

type CheckoutPageProps = {
  stripe: Promise<Stripe | null>;
  clientSecret: string;
  formData: CartFormData;
  address: Address;
  damageWaiverAllowed: boolean;
  damageWaiverEnabled: boolean;
  goBack: () => void;
  depositAmount: number;
  totalAmount: number;
};

export const CheckoutPage = (props: CheckoutPageProps) => {
  return (
    <Box>
      <Breadcrumbs
        aria-label="breadcrumbs"
        size={"sm"}
        separator={<ChevronRight fontSize={"small"} color={"inherit"} />}
      >
        <Link
          aria-label={"Go back to event info"}
          key={"home"}
          color="neutral"
          onClick={() => {
            props.goBack();
          }}
        >
          Event Info
        </Link>
        <Typography fontWeight={"600"}>Payment</Typography>
      </Breadcrumbs>
      <List variant="outlined">
        <ListItem
          endAction={
            <Button
              aria-label={"Edit"}
              onClick={() => {
                props.goBack();
              }}
            >
              Edit
            </Button>
          }
        >
          <ListItemDecorator>
            <Typography fontWeight={"600"}>Contact</Typography>
          </ListItemDecorator>
          <ListItemContent
            sx={{
              ml: 2,
            }}
          >
            <Typography>{props.formData.email}</Typography>
          </ListItemContent>
        </ListItem>
        <ListDivider inset={"gutter"} />
        <ListItem
          endAction={
            <Button
              aria-label={"Edit"}
              onClick={() => {
                props.goBack();
              }}
            >
              Edit
            </Button>
          }
        >
          <ListItemDecorator>
            <Typography fontWeight={"600"}>Deliver To</Typography>
          </ListItemDecorator>
          <ListItemContent
            sx={{
              ml: 2,
            }}
          >
            <Typography>
              {`${props.address.line1}, ${props.address.city}, ${props.address.state} ${props.address.postal_code}`}
            </Typography>
          </ListItemContent>
        </ListItem>
        {props.damageWaiverAllowed && (
          <>
            <ListDivider inset={"gutter"} />
            <ListItem
              endAction={
                <Button
                  aria-label={"Edit"}
                  onClick={() => {
                    props.goBack();
                  }}
                >
                  Edit
                </Button>
              }
            >
              <ListItemDecorator>
                <Typography fontWeight={"600"}>Damage Waiver</Typography>
              </ListItemDecorator>
              <ListItemContent
                sx={{
                  ml: 2,
                }}
              >
                <Typography>
                  {props.damageWaiverEnabled ? "Yes" : "No"}
                </Typography>
              </ListItemContent>
            </ListItem>
          </>
        )}
      </List>

      <Elements
        stripe={props.stripe}
        options={{
          clientSecret: props.clientSecret,
        }}
      >
        <PaymentInfo
          formData={props.formData}
          address={props.address}
          startingTotals={{
            depositAmount: props.depositAmount,
            totalAmount: props.totalAmount,
          }}
        />
      </Elements>
    </Box>
  );
};

export default CheckoutPage;
