import React from "react";
import { Avatar, Typography } from "@mui/joy";
import StarRateIcon from "@mui/icons-material/StarRate";
import Slider from "react-slick";
import SimpleNextArrow from "./SimpleNextArrow";
import SimplePrevArrow from "./SimplePrevArrow";

const formatter = new Intl.RelativeTimeFormat(undefined, {
  numeric: "auto",
});

const DIVISIONS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
];

function formatTimeAgo(date: Date) {
  let duration = (date.getTime() - new Date().getTime()) / 1000;

  for (let i = 0; i < DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return formatter.format(
        Math.round(duration),
        division.name as
          | "year"
          | "years"
          | "quarter"
          | "quarters"
          | "month"
          | "months"
          | "week"
          | "weeks"
          | "day"
          | "days"
          | "hour"
          | "hours"
          | "minute"
          | "minutes"
          | "second"
          | "seconds",
      );
    }
    duration /= division.amount;
  }
}
const RenderReview = ({
  title,
  age,
  review,
  img,
}: {
  title: string;
  age: Date;
  review: string;
  img?: string | undefined;
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginRight: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Avatar alt={title} src={img} />
        <Typography
          level={"h2"}
          textColor={"#193425"}
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            fontColor: "#193425",
            font: "Roboto",
          }}
        >
          {title}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <div>
          <StarRateIcon color={"warning"} />
          <StarRateIcon color={"warning"} />
          <StarRateIcon color={"warning"} />
          <StarRateIcon color={"warning"} />
          <StarRateIcon color={"warning"} />
        </div>
        <Typography textColor={"#193425"}>{formatTimeAgo(age)}</Typography>
      </div>
      <Typography textColor={"#193425"}>{review}</Typography>
    </div>
  );
};
const Reviews = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SimpleNextArrow />,
    prevArrow: <SimplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <RenderReview
        title={"Mary Kate Clarkson"}
        age={new Date(2024, 0, 20)}
        review={
          "Professional, safe and accommodating! Will be booking with Central Arkansas Inflatables again"
        }
      />
      <RenderReview
        title={"Hannah Cantrell"}
        age={new Date(2023, 11, 11)}
        review={
          "Such a great price for inflatable renting! i highly recommend this company! Both guys that came out to set it up were super nice and on time, i didn't have to lift a finger! We ordered the Pink and purple castle and it was just so big and amazing, the kids loved it so so much! Definitely my go to for any future parties!"
        }
      />
      <RenderReview
        title={"KMG K"}
        age={new Date(2023, 10, 26)}
        review={
          "I had to change the date of my event at the last minute due to weather issues. While other companies could’ve charged me an extra fee or chosen to not allow me to move the date, the Central Arkansas Inflatables team were not only flexible; but they offered excellent customer service, an excellent product, and were courteous and professional from the beginning until the end of the process. I highly recommend using this business. I will be a repeat customer!"
        }
      />
      <RenderReview
        title={"Shi Hui"}
        age={new Date(2023, 9, 8)}
        review={
          "Best price, best service. First time rent a bounce house and it was like a breeze. 15 minutes set up. We will definitely use and recommend this company !!"
        }
      />
      <RenderReview
        title={"James Worrell"}
        age={new Date(2023, 10, 20)}
        review={
          "Great deal, great fun and worth the money! They were great to work with as well!"
        }
      />
      <RenderReview
        title={"Rachel Threlkeld"}
        age={new Date(2023, 10, 11)}
        review={
          "We had a great experience! They showed up on time (actually early) for set up and were very polite with both drop off and pick up. Inflatable looked just like advertised and functioned perfectly. Would definitely use again!"
        }
      />
      <RenderReview
        title={"Ashlea Brazil"}
        age={new Date(2023, 7, 1)}
        review={
          "Caitlyn & Tre are the easiest and best people to work with!! They responded so fast and were very punctual. The water slide was HUGE and so fun for kids (& adults) of all ages!! We will definitely be using them again and will recommend them to alllllll family & friends!!"
        }
      />
      <RenderReview
        title={"Gabrielle Woodruff"}
        age={new Date(2023, 8, 14)}
        review={
          "Cannot say enough about this company. Super professional. Came early, communicated well, and helped me pick the perfect bounce house for my sons party. Pricing was super affordable and they did all of the work. Everyone was so friendly and helped make my sons party a blast. Definitely will be booking with them again soon."
        }
      />
    </Slider>
  );
};

export default Reviews;
