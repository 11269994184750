import { Container } from "@mui/joy";
import React from "react";
import Logo from "../components/Logo";

function NotFoundPage() {
  return (
    <Container>
      <Logo  size={'medium'}/>
      <h1>No page found here.</h1>
    </Container>
  );
}

export default NotFoundPage;
