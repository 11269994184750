import { Container, Typography } from "@mui/joy";
import React from "react";
import SEO from "../../components/SEO";

const TOS = () => {
    return (
        <Container>
            <SEO title={'Central Arkansas Inflatables - Terms of Service'}
                 description={'Terms of Service for inflatables!'}
                 name={'CentralArkansasInflatables'} type={''}/>
            <Typography level={"h1"} textColor={"orange"}>
                Terms of Service
            </Typography>
            <Typography component={"p"}>Last Updated: 02/14/2024</Typography>

            <h2>Welcome to Central Arkansas Inflatables</h2>
            <p>These Terms of Service ("Terms", "ToS") govern your use of our service provided by Central Arkansas
                Inflatables ("us", "we", or "our") located in Benton, Arkansas. Our services include the rental and
                delivery of bounce houses, water slides, and obstacle courses ("Service").</p>

            <h2>Agreement to Terms</h2>
            <p>By making a payment to Central Arkansas Inflatables, whether partial or in full, and/or receiving an
                inflatable or any equipment from us, you acknowledge that you have read, understood, and agree to be
                bound by these Terms of Service and any future amendments and additions to this agreement as published
                from time to time at our discretion. This act constitutes an agreement to our Service Contract, and
                these Terms include all rental agreements, policies, and guidelines associated with the Service.</p>

            <h2>Payments and Cancellations</h2>
            <p>Payments can be made via the methods outlined on our website or invoice. Full payment must be received
                before delivery of the rental equipment. Cancellations must be made 24 hours before the scheduled
                delivery time for a full refund. Cancellations made less than 24 hours before the scheduled delivery
                will be refunded at discretion of Central Arkansas Inflatables.</p>

            <h2>Delivery, Setup, and Pickup</h2>
            <p>We will deliver, set up, and pick up the inflatables at or up to 4 hours before the agreed-upon times. The customer is
                responsible for ensuring that the setup location is ready and safe for equipment setup. This includes ensuring power is within 75' for the setup area.</p>

            <h2>Use of Equipment</h2>
            <p>The customer agrees to supervise the equipment and its use at all times, following the Safety Guidelines
                provided. The customer is responsible for any damage to the equipment due to misuse or neglect.</p>

            <h2>Liability and Insurance</h2>
            <p>Central Arkansas Inflatables is not responsible for any injuries that occur while the rental equipment is
                in use. The customer agrees to hold Central Arkansas Inflatables harmless from any claims related to
                injuries or damage occurring during the rental period.</p>

            <h2>Changes to Terms of Service</h2>
            <p>We reserve the right to update and change these Terms of Service at any time. Any changes will be
                effective immediately upon posting the updated terms on our website. Your continued use of our Service
                after such changes constitutes your agreement to the new Terms.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at https://centralarkansasinflatables.com/contact.</p>
        </Container>
    );
};

export default TOS;
