import {
  CLEAR_CART,
  UPDATE_CART_ITEM,
  UPDATE_CART_ITEM_QUANTITY,
} from "./types";
import type { CartState, State } from "./types";

export const cartReducer = (state: CartState = { items: [] }, action: any) => {
  switch (action.type) {
    case CLEAR_CART:
      return { items: [], totalPrice: 0 };
    case UPDATE_CART_ITEM:
      const item = action.item;
      if (item && item.quantity > 0) {
        const index = state.items.findIndex((it) => it.id === item.id);
        const newItems = [...state.items];
        if (index !== -1) {
          newItems[index] = { ...item };
        } else {
          newItems.push({ ...item });
        }
        return { ...state, items: newItems };
      } else {
        const items = state.items.filter((it) => it.id !== item.id);
        return { ...state, items };
      }
    case UPDATE_CART_ITEM_QUANTITY:
      const quantity = action.quantity;
      const productId = action.productId;

      if (quantity === 0) {
        const items = state.items.filter((it) => it.id !== productId);
        return { ...state, items };
      } else {
        const index = state.items.findIndex((it) => it.id === productId);
        if (index !== -1) {
          const newItems = [...state.items];
          const item = { ...state.items[index], quantity };
          newItems[index] = { ...item };
          return { ...state, items: newItems };
        }
      }
      return state;

    default:
      return state;
  }
};

export const getCartItems = (state: State) => {
  return state.cart.items;
}
