import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, Link } from "@mui/joy";
import Slider from "react-slick";

function TopCarousel({ isMobile}: {isMobile: boolean}) {
  const items = [
    {
      imgKey: "6cdeb7d8-c490-4546-3ad8-c440b9272e00",
      alt: "18ft Palm Spring Obstacle Slide",
      url: "/product/28/18ft-palm-spring-water-slide-and-obstacle-course",
    },
    {
      imgKey: "b2cca69a-3697-4d43-05e3-8447342e1000",
      alt: "Slides",
      url: "/category/3",
    },
    {
      imgKey: "363e7282-c84c-4020-3ec8-74a467dc7100",
      alt: "Bounce Combo",
      url: "/category/4",
    },
    {
      imgKey: "a2fa94e8-88a2-4f3f-d251-2d985b1da200",
      alt: "Obstacle Course",
      url: "/category/2",
    },
    {
      imgKey: "edbf8cd8-86aa-4576-581a-37825c8dd200",
      alt: "Bounce House",
      url: "/category/4",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box width={"100%"}>
      <Slider {...settings}>
        {items.map((item, index) => {
          return (
            <Link
              aria-label={`Shop for products in the ${item.alt} category`}
              href={item.url}
              key={`${item.alt}-product`}
              width={"100%"}
              height={"100%"}
            >
              <LazyLoadImage
                key={`${item.alt}-product-${index}`}
                alt={item.alt}
                height={"1280"}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                srcSet={`https://centralarkansasinflatables.com/cdn-cgi/imagedelivery/6QiASA1pHsoYecw9egSmhw/${item.imgKey}/w=320   600w,
              https://centralarkansasinflatables.com/cdn-cgi/imagedelivery/6QiASA1pHsoYecw9egSmhw/${item.imgKey}/w=480   900w,
              https://centralarkansasinflatables.com/cdn-cgi/imagedelivery/6QiASA1pHsoYecw9egSmhw/${item.imgKey}/w=640   1300w,
               https://centralarkansasinflatables.com/cdn-cgi/imagedelivery/6QiASA1pHsoYecw9egSmhw/${item.imgKey}/w=960   1700w`}
                src={`https://centralarkansasinflatables.com/cdn-cgi/imagedelivery/6QiASA1pHsoYecw9egSmhw/${item.imgKey}/w=960`}
                wrapperProps={{
                  style: {
                    width: "100%",
                    height: "100%",
                  },
                }}
                visibleByDefault={index === 0 || (!isMobile && index === 1)}
                placeholderSrc={`https://centralarkansasinflatables.com/cdn-cgi/imagedelivery/6QiASA1pHsoYecw9egSmhw/b2cca69a-3697-4d43-05e3-8447342e1000/w=24`}
                width={"720"}
              />
            </Link>
          );
        })}
      </Slider>
    </Box>
  );
}

export default TopCarousel;
