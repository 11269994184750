import React, { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { Container, ListItem, Stack, Typography } from "@mui/joy";
import { purchase } from "../lib/events";
import { getCartItems } from "../reducers/cart";
import {useDispatch, useSelector} from "react-redux";
import SidewaysItemList from "./SidewaysItemList";
import {clearCart, setShoppingId} from "../reducers/actions";

const PaymentStatus = () => {
  const dispatch = useDispatch<any>();
  const stripe = useStripe();
  const [message, setMessage] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [totals, setTotals] = useState<any>({ totalAmount: 0 });
  const cartItems = useSelector(getCartItems);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret",
    );

    if (!clientSecret) {
      return;
    }

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) {
        return;
      }
      dispatch(setShoppingId(null));
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (paymentIntent.status) {
        case "succeeded":
          purchase(
            paymentIntent.amount / 100,
            paymentIntent.id,
            cartItems.map((item) => {
              return {
                item_name: item.name,
                quantity: item.quantity,
                price: item.price,
              };
            }),
          );
          setTotals({
            totalAmount: paymentIntent.amount / 100,
          });
          setSuccess(true);
          setMessage("Success! Payment received.");
          dispatch(clearCart())
          break;

        case "processing":
          setMessage(
            "Payment processing. We'll update you when payment is received.",
          );
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage("Payment failed. Please try another payment method.");
          break;

        default:

          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  return (
    <Container>
      <Stack justifyContent={"center"} textAlign={"center"}>
        <Typography color={'primary'} level={'h2'}>{message}</Typography>
        {success && (
          <Typography>
            We will call you the evening before your event with a delivery time
            which may be up to several hours early on our busy days. If you've
            provided your email address, you will receive an automatic receipt.
            Please review it for accuracy and read the reminders and tips
            section as soon as possible so that we may correct any incorrect
            information to eliminate causing issues on event day.
          </Typography>
        )}
        <ListItem>
          <SidewaysItemList
            items={[{ name: "Total", value: totals.totalAmount }]}
          />
        </ListItem>
      </Stack>
    </Container>
  );
};

export default PaymentStatus;
