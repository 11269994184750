import React, { useEffect } from "react";
import ProductCarousel from "../components/ProductCarousel";
import {Box, Container, Grid, Link, Typography} from "@mui/joy";
import { useSelector } from "react-redux";
import {
  getAllProductAvailability,
  getCategories,
  getProducts,
} from "../reducers/products";
import Reviews from "../components/Reviews";
import CardItem from "../components/CardItem";
import { BASE_URL } from "../index";
import SEO from "../components/SEO";
import type { ProductFromBackend } from "../types";
import Anchor from "../components/Anchor";
import TopCarousel from "../components/TopCarousel";
import { captureException } from "@sentry/react";

const Home = () => {
  const categories = useSelector(getCategories);
  const products = useSelector(getProducts);
  const [bestSellers, setBestSellers] = React.useState<ProductFromBackend[]>(
    [],
  );
  const [bestSellersLoading, setBestSellersLoading] = React.useState(true);
  const allProductAvailability = useSelector(getAllProductAvailability);

  useEffect(() => {
    try {
      const bestSellerRequest = fetch(`${BASE_URL}/products/bestSellers`);

      bestSellerRequest.then((response) => {
        try {
          const data = response.json() as Promise<{
            products: { id: number }[];
          }>;
          data.then((data: { products: { id: number }[] }) => {
            const flatMap = data.products.flatMap((product) => product.id);
            const productsToInclude = products.filter((product) =>
              flatMap.includes(product.id),
            );
              setBestSellers(
                productsToInclude.sort((a, b) => {
                  return flatMap.indexOf(a.id) - flatMap.indexOf(b.id);
                }),
              );
              setBestSellersLoading(false);
          });
        } catch (e) {
          captureException(e);

          console.error(e);
        }
      });
    } catch (e) {
      captureException(e);

      console.error(e);
      setBestSellers([]);
      setBestSellersLoading(true);
    }
  }, [products]);

  return (
    <div>
      <SEO
        title={`Central Arkansas Inflatables`}
        description="Central Arkansas Inflatables is the premier inflatable rental company in Central Arkansas. Proudly serving Little Rock, Benton, Bryant, Sherwood, Maumelle, and more!"
        name="CentralArkansasInflatables"
        type=""
      />

      <Box
        display={{
          xs: "none",
          sm: "block",
        }}
      >
        <TopCarousel isMobile={false} />
      </Box>
      <Box
        display={{
          xs: "block",
          sm: "none",
        }}
      >
        <TopCarousel isMobile={true} />
      </Box>
        <Container
            sx={{
                paddingTop: 2,
                paddingBottom: 2,
            }}
        >
            <Grid container alignItems="center" textAlign={"center"} spacing={2}>
                <Grid xs={12} md={12}>
                    <Typography level={"h2"} textAlign={"center"} mb={"10px"}>
                        Back to School Hits
                    </Typography>
                    <ProductCarousel
                        loading={bestSellersLoading}
                        textColor={"black"}
                        products={products.filter((product) => {
                            return product.id === 23 || product.id === 28 || product.id === 12 || product.id === 20 || product.id === 5 || product.id === 1 || product.id === 24
                        })}
                    />
                </Grid>
            </Grid>
        </Container>
      <Container
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        <Grid container alignItems="center" textAlign={"center"} spacing={2}>
          <Grid xs={12} md={12}>
            <Typography level={"h2"} textAlign={"center"} mb={"10px"}>
              Best Sellers
            </Typography>
            <ProductCarousel
              loading={bestSellersLoading}
              textColor={"black"}
              products={bestSellers}
            />
          </Grid>
        </Grid>
      </Container>

      <Anchor id={"rentals"} />
      <Container
        sx={{
          border: { xs: "0", md: "1px solid black" },
          borderRadius: "1rem",
          padding: "20px",
          marginTop: "48px",
          marginBottom: "48px",
        }}
      >
        <Typography level={"h2"} textAlign={"center"}>
          Shop All
        </Typography>

        <Grid container spacing={2} alignItems="center" textAlign={"center"}>
          {products
            .filter((product) => product.display)
            .map((product, index) => {
              return (
                <CardItem
                  key={`product-${index}`}
                  url={`/product/${product.id}/${product.slug}`}
                  image={product.heroImage || ""}
                  available={
                    (allProductAvailability[product.id] ?? product?.quantity) >
                    0
                  }
                  itemName={product.name}
                />
              );
            })
            .slice(0, 5)}
          <CardItem
            key={`product-all`}
            url={`/rentals`}
            image={
              "https://centralarkansasinflatables.com/cdn-cgi/imagedelivery/6QiASA1pHsoYecw9egSmhw/67dee7ad-8d35-417b-145e-886764976400/512"
            }
            available={true}
            itemName={"See All Products"}
          />
        </Grid>
      </Container>
      <Anchor id={"info"} />
      <Container
        sx={{
          marginTop: "48px",
          marginBottom: "48px",
        }}
      >
        <Typography level={"h2"} textAlign={"center"}>
          Welcome to Central Arkansas Inflatables!
        </Typography>
        <Typography textAlign={"center"}>
          Proudly serving Benton, Little Rock, Bryant, Sherwood, Maumelle, and more! Central Arkansas Inflatables is a family-owned and
          operated Inflatable and Bounce House rental company committed to bringing joy and
          excitement to any event! What
          sets us apart is our unrivaled dedication to serving the customer, and our extensive inflatable inventory, from Bounce House to Water Slides and Obstacle Course.
            Allow us to help you create lasting memories at your next event!
        </Typography>
      </Container>
      <Box
        sx={{
          backgroundColor: "#00ff6f",
          padding: "20px",
          marginTop: "48px",
          marginBottom: "48px",
        }}
      >
        <Container>
          <Typography
            level={"h2"}
            textAlign={"center"}
            textColor={"#193425"}
            sx={{
              paddingTop: "20px",
            }}
          >
            What Our Customers Think
          </Typography>
            <Typography
                         textAlign={"center"}
                         textColor={"#193425"}>
            <Link

                sx={{
                    paddingBottom: "20px",
                }}
                href={'https://maps.app.goo.gl/HrRNdDGCvdwT6UeC9'}
                target={'_blank'}
                rel={'noreferrer noopener'}
            >
                See All
            </Link>
            </Typography>
          <Reviews />
        </Container>
      </Box>

      <Container
        sx={{
          border: { xs: "0", md: "1px solid black" },
          borderRadius: "1rem",
          padding: "20px",
          marginTop: "48px",
          marginBottom: "48px",
        }}
      >
        <Typography level={"h2"} textAlign={"center"} pb={"5px"}>
          Shop By Category
        </Typography>

        <Grid container spacing={2} alignItems="center" textAlign={"center"}>
          <CardItem
            url={`/order-by-date/`}
            image={
              "https://centralarkansasinflatables.com/cdn-cgi/imagedelivery/6QiASA1pHsoYecw9egSmhw/0250edee-88e7-4ffe-c61c-485fa2223400/512"
            }
            key={"order-by-date"}
            available={true}
            itemName={"Order By Date"}
          />

          {categories
            .filter((category) => category.display)
            .map((category) => {
              return (
                <CardItem
                  key={"category-" + category.id}
                  url={`/category/${category.id}/${category.slug}`}
                  image={category.heroImage}
                  available={true}
                  itemName={category.name}
                />
              );
            })}
        </Grid>
      </Container>
    </div>
  );
};

export default Home;
