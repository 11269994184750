import { useSelector } from "react-redux";
import { getCategories } from "../reducers/products";
import { Container, Grid } from "@mui/joy";
import React from "react";
import CartBanner from "../components/CartBanner";
import CardItem from "../components/CardItem";
import type { Category } from "../types";
import SEO from "../components/SEO";

const CategoryList = () => {
  const categories = useSelector(getCategories);

  return (
    <Container
      sx={{
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
        <SEO
            title="Central Arkansas Inflatables - Categories"
            description="View all Bounce House, Water Slide, and Inflatable Rentals from Central Arkansas Inflatables. Proudly serving Little Rock, Benton, Bryant, Sherwood, Maumelle, and more!"
            name="CentralArkansasInflatables"
            type=""
        />
      <CartBanner />
      <Grid
        container
        alignItems="center"
        textAlign={"center"}
        paddingTop={3}
        spacing={2}
      >
        {categories.map((category: Category, index: number) => {
          return (
            <CardItem
              key={`${index}-categorycard`}
              url={`/category/${category.id}/${category.slug}`}
              image={category.heroImage}
              available={true}
              itemName={category.name}
            />
          );
        })}
      </Grid>
    </Container>
  );
};

export default CategoryList;
