import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/joy";
import type { ProductFromBackend } from "../types";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

export default function RentalCard({
  product,
  available,
}: {
  product: ProductFromBackend;
  available: boolean;
}) {
  const navigate = useNavigate();
  return (
    <Grid
      xs={12}
      sm={6}
      md={4}
      key={`${product.id}-item`}
      aria-label={"Click for More Info"}
      onClick={() => {
        navigate(`/product/${product.id}/${product?.slug || ""}/`);
      }}
    >
      <Card>
        <Box>
          <LazyLoadImage
            alt={product.name}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            src={product.heroImage}
            width={"200"}
            height={"200"}
          />
        </Box>
        {!available && (
          <LazyLoadImage
            src={
              "https://centralarkansasinflatables.com/cdn-cgi/imagedelivery/6QiASA1pHsoYecw9egSmhw/3f5e0da9-d4dd-410c-b4c6-6ce815765300/512"
            }
            width={"200"}
            height={"200"}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
            alt={"Sold Out"}
          />
        )}
        <CardContent orientation={"vertical"}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography component="h5">{product.name}</Typography>
            <Typography component="p">${product.price}</Typography>
          </Box>
          <Link
            aria-label={"More Info"}
            sx={{
              width: { xs: "45%", md: "30%" },
            }}
            disabled={!available}
            href={`/product/${product.id}/${product?.slug || ""}/`}
          >
            <Button
              aria-label={"More Info"}
              fullWidth={false}
              size={"sm"}
              variant={"solid"}
              disabled={!available}
            >
              More Info
            </Button>
          </Link>
        </CardContent>
      </Card>
    </Grid>
  );
}
