import { Button } from "@mui/joy";
import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <Button
        aria-label={'Back'}
      sx={{
        margin: "8px",
        backgroundColor: "red",
      }}
      onClick={() => {
        navigate(-1);
      }}
    >
      {"< Back"}
    </Button>
  );
};

export default BackButton;
