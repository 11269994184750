import React, {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";

const Return = () => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        fetch(`/session-status?session_id=${sessionId}`)
            .then((res) => res.json())
            .then((data: any) => {
                setStatus(data.status);
                setCustomerEmail(data.customer_email);
            });
    }, []);

    if (status === 'open') {
        return (
            <Navigate to="/checkout"/>
        )
    }

    if (status === 'complete') {
        return (
            <section id="success">
                <p>
                    We appreciate your business! A confirmation email will be sent to your {customerEmail}.

                    If you have any questions, please email <a href="mailto:tre@centralarkansasinflatables.com">tre@centralarkansasinflatables.com</a>.
                </p>
            </section>
        )
    }

    return null;
}

export default Return;
