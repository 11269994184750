import {
  Box,
  Button,
  Container,
  Link,
  List,
  ListItemButton,
  ModalClose,
  Typography,
} from "@mui/joy";
import React, { useRef } from "react";
import { ShoppingCart } from "@mui/icons-material";
import { Link as NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CartBadge from "./CartBadge";

function NavLinks(props: { isMobile: boolean; cartCount: number }) {
  const navigate = useNavigate();
  const closeRef = useRef<HTMLButtonElement | null>(null);
  const links = [
    { label: "Rentals", href: "/rentals" },
    { label: "Info", href: "/#info" },
    { label: "Contact Us", href: "/contact" },
  ];

  const mobileLinks = [
    { label: "Book Now", href: "/order-by-date" },
    { label: "Rentals", href: "/rentals" },
    {
      label: "Categories",
      href: "/category",
    },
    { label: "Info", href: "/#info" },
    { label: "Contact Us", href: "/contact" },
  ];

  if (props.isMobile) {
    return (
      <Box>
        <ModalClose
          ref={(item) => {
            closeRef.current = item;
          }}
          id="close-icon"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 0.5,
            ml: "auto",
            mt: 1,
            mr: 2,
          }}
        />

        <List
          size="lg"
          component="nav"
          sx={{
            flex: "none",
            fontSize: "xl",
            "& > div": { justifyContent: "center" },
          }}
        >
          {mobileLinks.map((link, index) => (
            <Link
              component={NavLink}
              key={`link-${link.label}-${index}`}
              to={link.href}
              aria-label={`Go to ${link.label}`}
              onClick={() => {
                closeRef.current?.click();
              }}
            >
              <ListItemButton key={link.label}>{link.label}</ListItemButton>
            </Link>
          ))}
        </List>
      </Box>
    );
  }

  return (
    <Container sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
      {links.map((link) => (
        <Link
          component={NavLink}
          key={link.label}
          to={link.href}
          sx={{
            marginLeft: "15px",
          }}
          aria-label={`Go to ${link.label}`}
        >
          <Typography
            textColor={"white"}
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              "&:hover": {
                opacity: "0.75",
              },
              font: "Roboto",
            }}
          >
            {link.label}
          </Typography>
        </Link>
      ))}
      <Link
        aria-label={"Go to Cart"}
        component={NavLink}
        sx={{
          marginLeft: "15px",
        }}
        to={"/cart"}
      >
        <ShoppingCart
          fontSize={"inherit"}
          sx={{
            color: "white",
            "&:hover": {
              opacity: "0.75",
            },
            fontSize: 48,
          }}
        />
        <CartBadge count={props.cartCount} />
      </Link>
      <Button
        aria-label={"Book Now"}
        sx={{
          marginLeft: "15px",
        }}
        size="lg"
        color={"danger"}
        onClick={() => {
          navigate("/order-by-date");
        }}
      >
        Book Now
      </Button>
    </Container>
  );
}

export default NavLinks;
